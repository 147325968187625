import React, { Fragment, useState, useEffect } from "react";
import { Typography, makeStyles, Box, Modal } from "@material-ui/core";
import { MainButton, GrayButton } from "src/components";
import Warning from "src/assets/images/warning_modal.png";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "#FFFFFF",
    border: "2px solid #FFF",
    boxShadow: 24,
    padding: 30,
    textAlign: "center",
    borderRadius: "20px",
    outline: "none",
  },
  image: {
    width: 120,
    height: 120,
  },
  button: {
    minWidth: "120px",
    margin: 10,
    "&:hover": {
      minWidth: "120px",
    },
  },
}));

const ModalCustom = ({
  isOpen,
  setIsModalOpen,
  title = "คุณต้องการยกเลิกใช่หรือไม่",
  subTitle = "หากคุณยกเลิก ข้อมูลจะไม่ถูกบันทึก",
  onSubmit,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);
  // const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.root}>
          <img className={classes.image} alt="Logo" src={Warning} />
          <Typography
            id="modal-modal-title"
            variant="subtitle2"
            component="h2"
            style={{ paddingTop: "4px", paddingBottom: "10px" }}
          >
            {title}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ paddingBottom: "10px" }}
          >
            {subTitle}
          </Typography>
          <MainButton
            title={"ไม่"}
            className={classes.button}
            onClick={handleClose}
          />
          <GrayButton
            title={"ใช่"}
            className={classes.button}
            onClick={() => {
              onSubmit();
              handleClose();
            }}
          />
        </Box>
      </Modal>
    </Fragment>
  );
};

export default ModalCustom;
