import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch } from "src/store";
import { makeStyles } from "@material-ui/core";
import { ModalCustom, MainButton, NegativeButton } from "src/components";
import Stack from "@mui/material/Stack";
import {
  formatDate,
  formatDateWithTime,
  numberWithCommas,
  invoiceNumberFormat,
} from "src/utils/utilities";
import { deleteInvoiceByID, printInvoice } from "src/actions/InvoiceAction";
import {
  LocalPrintshopOutlined as LocalPrintshopOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "900",
    },
  },
}));

const FindInvoiceTable = ({ search, items }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rows, setRows] = useState(items);
  const [selectedDelete, setSelectedDelete] = useState("");
  const handleDeleteInvoice = () => {
    // console.log("handleDeleteInvoice", selectedDelete);
    dispatch(deleteInvoiceByID(selectedDelete));
  };

  useEffect(() => {
    setRows(items);
  }, [items]);

  const handlePrintInvoice = (value) => {
    dispatch(printInvoice({ invoiceID: value }));
  };

  const columns = [
    {
      field: "invoiceID",
      headerName: "หมายเลขใบวางบิล/ใบแจ้งหนี้",
      width: 250,
      valueFormatter: (params: GridValueFormatterParams) => {
        return invoiceNumberFormat(params.value);
      },
    },
    {
      field: "TotalAmount",
      headerName: "ราคา",
      width: 250,
      valueFormatter: (params: GridValueFormatterParams) => {
        return numberWithCommas(params.value);
      },
    },
    {
      field: "merchantName",
      headerName: "ชื่อร้านค้า",
      width: 250,
    },
    {
      field: "CreatedAt",
      headerName: "วันที่สร้างใบวางบิล",
      sortable: false,
      width: 250,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDateWithTime(params.value);
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={0}>
            <MainButton
              type="button"
              title="พิมพ์"
              onClick={() => handlePrintInvoice(params.id)}
              startIcon={<LocalPrintshopOutlinedIcon />}
            />
            <NegativeButton
              type="button"
              title="ลบใบวางบิล"
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setSelectedDelete(params.id);
              }}
            />
            {/* <MainButton
              type="button"
              title="ลบ"
              color="red"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setSelectedDelete(params.id);
              }}
            /> */}
          </Stack>
        );
      },
    },
  ];

  return (
    <div style={{ height: 470, width: "100%" }}>
      <DataGrid
        className={classes.table}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(data) => data.invoiceID}
      />
      <ModalCustom
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={handleDeleteInvoice}
      />
    </div>
  );
};

export default FindInvoiceTable;
