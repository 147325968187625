import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import { ModalCustom, MainButton } from "src/components";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { formatDate, numberWithCommas } from "src/utils/utilities";
import { EditOutlined as EditOutlinedIcon } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "900",
    },
  },
}));

const CatalogTable = ({ search, catalogs, handleDeleteCatalog }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleEditCatalog = (params) => {
    navigate(`/app/catalog/edit/${params.id}`);
  };
  const [rows, setRows] = useState(catalogs);
  const [selectIndex] = useState();

  const columns = [
    { field: "id", headerName: "ลำดับ", width: 100 },
    { field: "name", headerName: "ชื่อสินค้า", width: 550 },
    { field: "unit", headerName: "หน่วย", width: 150 },
    {
      field: "price",
      headerName: "ราคา",
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return numberWithCommas(params.value);
      },
    },
    {
      field: "updated_date",
      headerName: "อัพเดทล่าสุด",
      sortable: false,
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDate(params.value);
      },
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={0}>
            <MainButton
              type="button"
              title="แก้ไขสินค้า"
              startIcon={<EditOutlinedIcon />}
              color="red"
              onClick={() => handleEditCatalog(params)}
            />
            {/* <IconButton
              startIcon={<BackspaceOutlinedIcon />}
              color="red"
              onClick={() => {
                setIsModalOpen(!isModalOpen);
                setSelectIndex(params.id);
              }}
            /> */}
          </Stack>
        );
      },
    },
  ];

  const requestSearch = (searchVal: String) => {
    if (searchVal === undefined) return;
    const filterRows = catalogs.filter((row) => {
      return row.name.includes(searchVal);
    });
    setRows(filterRows);
  };

  useEffect(() => {
    requestSearch(search);
  }, [search]);

  useEffect(() => {
    setRows(catalogs);
  }, [catalogs]);

  return (
    <div style={{ height: 670, width: "100%" }}>
      <DataGrid
        className={classes.table}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
      <ModalCustom
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={() => handleDeleteCatalog(selectIndex)}
      />
    </div>
  );
};

export default CatalogTable;
