import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

const NegativeButton = ({
  className,
  title,
  onClick,
  href,
  type,
  isDisable,
  startIcon,
  color,
  id,
  name,
}) => {
  const classes = useStyles();

  const buttonComponent = () => {
    if (!!href && href.length) return RouterLink;

    switch (type) {
      case "submit":
      case "button":
      default:
        return "button";
    }
  };

  const dynamicProps = () => {
    const props = {};
    if (!!href && href.length) {
      props.to = href;
    }

    return props;
  };

  return (
    <Button
      className={clsx(classes.root)}
      onClick={onClick}
      style={{ textTransform: "none" }}
      component={buttonComponent()}
      startIcon={startIcon}
      type={type}
      {...dynamicProps()}
      disabled={isDisable}
      id={id}
      name={name}
    >
      {title}
    </Button>
  );
};

export default NegativeButton;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5px",
    minWidth: "120px",
    minHeight: "30px",
    maxWidth: "138px",
    maxHeight: "38px",
    alignItems: "center",
    borderRadius: "4px",
    borderColor: theme.palette.btn.red,
    backgroundColor: theme.palette.btn.white,
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "condensed",
    fontStyle: "normal",
    lineHeight: 1.64,
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.palette.btn.red,
    border: `solid 1px`,
    // "&:hover": {
    //   border: theme.palette.btn.mainBorderHover,
    //   opacity: 1,
    //   cursor: "pointer",
    //   color: theme.palette.text.btnMainHover,
    //   minWidth: "120px",
    //   minHeight: "30px",
    //   maxWidth: "138px",
    //   maxHeight: "38px",
    //   borderRadius: "4px",
    //   lineHeight: 1.64,
    //   boxShadow: "0 1px 2px 0 rgba(63, 63, 68, 0.15)",
    // },
  },
}));
