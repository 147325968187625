import { apiCall } from "src/utils/axios";

export const apiGetDashboardSummary = (payload) => {
  return apiCall("GET", "api/v1/summary/detail", null, payload);
};

export const apiGetYearlyDashboardSummary = (payload) => {
  return apiCall("GET", "api/v1/yearly/summary/detail", null, payload);
};

export const apiGetTopProductDetail = (payload) => {
  return apiCall(
    "GET",
    `api/v1/yearly/summary/top-product/detail`,
    null,
    payload
  );
};

export const apiGetOverview = (payload) => {
  return apiCall("GET", `api/v1/yearly/summary/overview`, null, payload);
};

export const apiGetSummaryEachMonth = () => {
  return apiCall("GET", `api/v1/yearly/summary/month-sale`, null, null);
};
