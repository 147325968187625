import { MERCHANT_UPDATE, MERCHANTS_UPDATE } from "src/actions/MerchantAction";

export const MERCHANT_STATE = "merchant";
const INITIAL_STATE = {
  Merchants: [],
  Merchant: {},
};

export const MerchantReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MERCHANTS_UPDATE:
      return { ...state, Merchants: action.payload };
    case MERCHANT_UPDATE:
      return { ...state, Merchant: action.payload };
    default:
      return state;
  }
};

export const MerchantSelector = (state) => state[MERCHANT_STATE];
