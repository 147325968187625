import font_Roboto from "../assets/fonts/Roboto-Regular.woff";

const Roboto = {
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: 400,
  fontDisplay: "swap",
  src: `
    local('Roboto'),
    local('Roboto-Regular'),
    url(${font_Roboto}) format('woff')
  `,
};

export default Roboto;
