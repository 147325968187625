import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";

const UnderlineButton = ({
  className,
  title,
  onClick,
  href,
  type,
  isDisable,
  startIcon,
  color,
  id,
  name,
}) => {
  const classes = useStyles();

  const buttonComponent = () => {
    if (!!href && href.length) return RouterLink;

    switch (type) {
      case "submit":
      case "button":
      default:
        return "button";
    }
  };

  const dynamicProps = () => {
    const props = {};
    if (!!href && href.length) {
      props.to = href;
    }

    return props;
  };

  return (
    <Button
      className={clsx(classes.root, className)}
      onClick={onClick}
      style={{ textTransform: "none" }}
      component={buttonComponent()}
      startIcon={startIcon}
      type={type}
      {...dynamicProps()}
      disabled={isDisable}
      id={id}
      name={name}
    >
      <u>{title}</u>
    </Button>
  );
};

export default UnderlineButton;

const useStyles = makeStyles((theme) => ({
  root: {
    // margin: "5px",
    minWidth: "120px",
    minHeight: "2.5rem",
    maxWidth: "138px",
    alignItems: "center",
    backgroundColor: theme.palette.btn.whiteBorder,
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "condensed",
    fontStyle: "normal",
    lineHeight: 1.64,
    letterSpacing: "normal",
    textAlign: "center",
  },
}));
