import config, { AuthorizeApi } from "../config";
import _ from "lodash";
import { getAccessToken } from "../utils/session";

export const ERROR_SESSION_LOST = "error_session_lost";
export const ERROR_CONNECTION_TIMEOUT = "error_connection_timeout";

const handleStatus = (json) => {
  if (json.errors) {
    throw new Error(json.errors[0]?.message || "GraphQL Error");
  }
  if (json.success || json.data) {
    return json;
  }
  throw new Error("response.data not found");
};

export const apiCall = (type, uri, req, body) => {
  if (body) {
    body = JSON.stringify(body);
  }
  let params = [];
  _.forEach(req || {}, (val, key) =>
    params.push(`${key}=${encodeURIComponent(val)}`)
  );
  let url = `${config().API_ENDPOINT}${uri}`;
  if (params.length > 0) {
    url = `${url}?${params.join("&")}`;
  }

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    Authorization: getAccessToken(), //? 'Bearer ' + getAccessToken() : undefined ,
  };
  console.log("####### Header #####", headers);
  return fetch(url, {
    // credentials: 'include',
    method: type,
    headers: headers,
    body: body,
  })
    .then((data) => {
      if (data.status == 200) {
        return data.json();
      } else if (data.status == 401) {
        window.location = window.location.origin + "/login";
        throw new Error("Invalid Token");
      }
      console.log("####### >", url, data);
      console.log("body >>> ", body);

      throw new Error(
        "Please contact administrator (status " + data.status + ")"
      );
    })
    .then((json) => {
      console.log("||||||| API ", url, "\n", json);
      return handleStatus(json);
    })
    .catch((err) => console.log(err));
};

export const apiQuery = (query, variables) => {
  let body = {
    query: query,
    variables: variables || {},
  };
  let url = `query`;
  return apiCall("POST", AuthorizeApi + url, null, body);
};
