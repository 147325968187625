import { put, call, take } from "redux-saga/effects";
import {
  CATALOG_GET_LIST,
  updateCatalogList,
  CATALOG_GET_BY_ID,
  updateCatalog,
  CATALOG_EDIT,
  CATALOG_DELETE,
  CATALOG_CREATE,
  CATALOG_UPLOAD,
} from "src/actions/CatalogAction";
import {
  apiGetCatalogList,
  apiGetCatalogByID,
  apiEditCatalogByID,
  apiDeleteCatalogByID,
  apiCreateCatalog,
  apiUploadImageCatalog
} from "src/api/CatalogApi";
import { handleStatusError, handleStatusSuccess } from ".";
import { hideSpinner, showSpinner } from "src/components";

export function* workerGetCatalogList(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetCatalogList, action?.payload);
    if (data.message) {
      yield put(updateCatalogList(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetCatalogList() {
  while (true) {
    const action = yield take(CATALOG_GET_LIST);
    yield call(workerGetCatalogList, action);
  }
}

export function* workerGetCatalogByID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetCatalogByID, action?.payload);
    if (data.message) {
      yield put(updateCatalog(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetCatalogByID() {
  while (true) {
    const action = yield take(CATALOG_GET_BY_ID);
    yield call(workerGetCatalogByID, action);
  }
}

export function* workerEditCatalogByID(action) {
  try {
    yield call(showSpinner);
    const navigate = action.navigate;

    const { data } = yield call(apiEditCatalogByID, action?.payload);
    if (data.message) {
      yield call(handleStatusSuccess, "Update Catalog Success");
      navigate(`/app/catalog`);
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherEditCatalogByID() {
  while (true) {
    const action = yield take(CATALOG_EDIT);
    yield call(workerEditCatalogByID, action);
  }
}

export function* workerDeleteCatalogByID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiDeleteCatalogByID, action?.payload);
    if (data.message) {
      yield call(handleStatusSuccess, "Delete Catalog Success");
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherDeleteCatalogByID() {
  while (true) {
    const action = yield take(CATALOG_DELETE);
    yield call(workerDeleteCatalogByID, action);
  }
}

export function* workerCreateCatalogByID(action) {
  try {
    yield call(showSpinner);
    const navigate = action.navigate;

    const { data } = yield call(apiCreateCatalog, action?.payload);
    if (data.message) {
      yield call(handleStatusSuccess, "Create Catalog Success");
      navigate(`/app/catalog`);
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherCreaetCatalogByID() {
  while (true) {
    const action = yield take(CATALOG_CREATE);
    yield call(workerCreateCatalogByID, action);
  }
}

export function* workerUploadImageCatalog(action) {
  try {
    yield call(showSpinner);
    const navigate = action.navigate;

    const { data } = yield call(apiUploadImageCatalog, action?.payload);
    if (data.message) {
      yield call(handleStatusSuccess, "Upload Image Success");
      window.location.reload(true);
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherUploadImageCatalog() {
  while (true) {
    const action = yield take(CATALOG_UPLOAD);
    yield call(workerUploadImageCatalog, action);
  }
}
