export const DASHBOARD_GET_SUMMARY = "DASHBOARD_GET_SUMMARY";
export const UPDATE_DASHBOARD_SUMMARY = "UPDATE_DASHBOARD_SUMMARY";
export const DASHBOARD_YEARLY_GET_SUMMARY = "DASHBOARD_YEARLY_GET_SUMMARY";
export const UPDATE_DASHBOARD_YEARLY_SUMMARY =
  "UPDATE_DASHBOARD_YEARLY_SUMMARY";
export const DASHBOARD_TOP_PRODUCT_DETAIL = "DASHBOARD_TOP_PRODUCT_DETAIL";
export const UPDATE_DASHBOARD_TOP_PRODUCT_DETAIL =
  "UPDATE_DASHBOARD_TOP_PRODUCT_DETAIL";
export const DASHBOARD_OVERVIEW = "DASHBOARD_OVERVIEW";
export const UPDATE_DASHBOARD_OVERVIEW = "UPDATE_DASHBOARD_OVERVIEW";
export const DASHBOARD_SUMMART_EACH_MONTH = "DASHBOARD_SUMMART_EACH_MONTH";
export const UPDATE_DASHBOARD_SUMMART_EACH_MONTH =
  "UPDATE_DASHBOARD_SUMMART_EACH_MONTH";

export const getDashboardSummary = (data) => ({
  type: DASHBOARD_GET_SUMMARY,
  payload: data,
});

export const updateDashboardSummary = (data) => ({
  type: UPDATE_DASHBOARD_SUMMARY,
  payload: data,
});

export const getDashboardYearlySummary = (data) => ({
  type: DASHBOARD_YEARLY_GET_SUMMARY,
  payload: data,
});

export const updateDashboardYearlySummary = (data) => ({
  type: UPDATE_DASHBOARD_YEARLY_SUMMARY,
  payload: data,
});

export const getTopProductDetail = (data) => ({
  type: DASHBOARD_TOP_PRODUCT_DETAIL,
  payload: data,
});

export const updateTopProductDetail = (data) => ({
  type: UPDATE_DASHBOARD_TOP_PRODUCT_DETAIL,
  payload: data,
});

export const getOverview = (data) => ({
  type: DASHBOARD_OVERVIEW,
  payload: data,
});

export const updateOverview = (data) => ({
  type: UPDATE_DASHBOARD_OVERVIEW,
  payload: data,
});

export const getSummaryEachMonth = (data) => ({
  type: DASHBOARD_SUMMART_EACH_MONTH,
  payload: data,
});

export const updateSummaryEachMonth = (data) => ({
  type: UPDATE_DASHBOARD_SUMMART_EACH_MONTH,
  payload: data,
});
