import forEach from "lodash/forEach";

export const queryParamsToMap = (str) => {
  const obj = {};
  const splitted = str
    .replace(/^\?/, "")
    .split("&")
    .reduce((arr, item) => {
      const [key, value] = item.split("=");
      arr[key] = value;
      return arr;
    }, {});

  forEach(splitted, (value, key) => (obj[key] = value));
  return obj;
};
