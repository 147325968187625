import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import { ModalCustom, MainButton, NegativeButton } from "src/components";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  numberWithCommas,
  billNumberFormat,
} from "src/utils/utilities";
import { useDispatch } from "src/store";
import { deleteBillByID } from "src/actions/BillAction";
import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "900",
    },
  },
}));

const BillTable = ({ search, bills }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDeleteBill, setSelectedDeleteBill] = useState("");
  const navigate = useNavigate();
  const handleEditBill = (params) => {
    navigate(`/app/edit-bill/${params.id}`);
  };
  const [rows, setRows] = useState(bills);

  useEffect(() => {
    setRows(bills);
  }, [bills]);

  const requestSearch = (searchVal: String) => {
    if (searchVal === undefined) return;
    const filterRows = rows.filter((row) => {
      return row.personalID.includes(searchVal);
    });
    setRows(filterRows);
  };

  const handleDeleteBill = () => {
    dispatch(deleteBillByID(selectedDeleteBill));
  };

  useEffect(() => {
    requestSearch(search);
  }, [search]);

  const columns = [
    {
      field: "billID",
      headerName: "หมายเลขบิล",
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return billNumberFormat(params.value);
      },
    },
    { field: "merchantName", headerName: "ชื่อร้านค้า", width: 350 },
    {
      field: "billTotalAmount",
      headerName: "จำนวนเงิน",
      width: 250,
      valueFormatter: (params: GridValueFormatterParams) => {
        return numberWithCommas(params.value);
      },
    },
    {
      field: "bill_created_date",
      headerName: "วันที่สร้าง",
      sortable: false,
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDate(params.value);
      },
    },
    {
      field: "paymentMethod",
      headerName: "หมายเหตุ",
      sortable: false,
      width: 200,
    },
    {
      field: "action",
      headerName: "Action",
      width: 350,
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={0}>
            <MainButton
              type="button"
              title="แก้ไขบิล"
              color="red"
              startIcon={<EditOutlinedIcon />}
              onClick={() => handleEditBill(params)}
            />
            <NegativeButton
              type="button"
              title="ลบบิล"
              startIcon={<DeleteOutlineOutlinedIcon />}
              onClick={() => {
                setSelectedDeleteBill(params.id);
                setIsModalOpen(!isModalOpen);
              }}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <div style={{ height: 670, width: "100%" }}>
      <DataGrid
        className={classes.table}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(data) => data.billID}
      />
      <ModalCustom
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        onSubmit={handleDeleteBill}
      />
    </div>
  );
};

export default BillTable;
