export const setAccessToken = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.clear();
    // delete axios.defaults.headers.common.Authorization;
  }
};

export const getAccessToken = () => {
  return `Bearer ${localStorage.getItem("accessToken")}`;
};
