export const MERCHANT_GET = "MERCHANT_GET";
export const MERCHANT_GET_BY_ID = "MERCHANT_GET_BY_ID";
export const MERCHANTS_UPDATE = "MERCHANTS_UPDATE";
export const MERCHANT_CREATE = "MERCHANT_CREATE";
export const MERCHANT_EDIT = "MERCHANT_EDIT";
export const MERCHANT_UPDATE = "MERCHANT_UPDATE";
export const MERCHANT_DELETE = "MERCHANT_DELETE";

export const getMerchant = (data) => ({
  type: MERCHANT_GET,
  payload: data,
});

export const getMerchantByID = (data) => ({
  type: MERCHANT_GET_BY_ID,
  payload: data,
});

export const updateMerchants = (data) => ({
  type: MERCHANTS_UPDATE,
  payload: data,
});

export const updateMerchant = (data) => ({
  type: MERCHANT_UPDATE,
  payload: data,
});

export const editMerchant = (data, navigate) => ({
  type: MERCHANT_EDIT,
  payload: data,
  navigate: navigate,
});

export const createMerchant = (data, navigate) => ({
  type: MERCHANT_CREATE,
  payload: data,
  navigate: navigate,
});

export const deleteMerchant = (data) => ({
  type: MERCHANT_DELETE,
  payload: data,
});
