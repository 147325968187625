import { apiCall } from "src/utils/axios";

export const apiLogin = (payload) => {
  return apiCall("POST", "api/login", payload, null);
};

export const apiGetUser = (payload) => {
  //   return apiCall("POST", "api/login", payload, null);
  return null;
};
