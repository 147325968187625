import React, { Suspense, Fragment, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import DashboardLayout from "../src/layouts/DashboardLayout";
import LoadingScreen from "src/components/LoadingScreen";
const NotFoundView = lazy(() => import("src/views/errors/NotFoundView"));
const CatalogView = lazy(() => import("src/views/Catalog/TableCatalog"));
const CreateCatalogView = lazy(() => import("src/views/Catalog/CreateCatalog"));
const EditCatalogView = lazy(() => import("src/views/Catalog/EditCatalog"));
const TableMerchantView = lazy(() =>
  import("src/views/Merchant/TableMerchant")
);
const CreateMerchantView = lazy(() =>
  import("src/views/Merchant/CreateMerchant")
);
const EditMerchantView = lazy(() => import("src/views/Merchant/EditMerchant"));
const TableBillView = lazy(() => import("src/views/FindBill/BillTable"));
const CreateBillView = lazy(() => import("src/views/FindBill/CreateBill"));
const EditBillView = lazy(() => import("src/views/FindBill/EditBill"));
const CreateInvoiceView = lazy(() => import("src/views/Invoice/CreateInvoice"));
const FindInvoiceView = lazy(() => import("src/views/Invoice/FindInvoice"));
const ReturnCatalogView = lazy(() => import("src/views/Catalog/ReturnCatalog"));
const ReamCatalogView = lazy(() => import("src/views/Catalog/ReamCatalog"));
const DashboardView = lazy(() => import("src/views/Dashboard"));
const YearlyDashboard = lazy(() => import("src/views/YearlyDashboard"));
const OverviewDashboard = lazy(() => import("src/views/OverviewDashboard"));
const AuthenticationView = lazy(() => import("src/views/Authentication"));

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.element;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            element={
              <Guard>
                <Layout>{Component}</Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: "/404",
    element: <NotFoundView />,
  },
  {
    exact: true,
    path: "/",
    element: <AuthenticationView />,
  },
  {
    exact: true,
    path: "/login",
    element: <AuthenticationView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app",
    element: <CreateBillView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/catalog",
    element: <CatalogView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/catalog/create",
    element: <CreateCatalogView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/catalog/edit/:id",
    element: <EditCatalogView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/merchant",
    element: <TableMerchantView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/merchant/create",
    element: <CreateMerchantView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/merchant/edit/:id",
    element: <EditMerchantView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/bill",
    element: <TableBillView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/create-bill",
    element: <CreateBillView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/edit-bill/:id",
    element: <EditBillView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/create-invoice",
    element: <CreateInvoiceView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/invoice",
    element: <FindInvoiceView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/dashboard",
    element: <DashboardView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/year/dashboard",
    element: <YearlyDashboard />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/all/dashboard",
    element: <OverviewDashboard />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/return-catalog",
    element: <ReturnCatalogView />,
  },
  {
    exact: true,
    layout: DashboardLayout,
    path: "/app/ream-catalog",
    element: <ReamCatalogView />,
  },
];

export default routes;
