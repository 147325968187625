import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  formatDate,
  numberWithCommas,
  billNumberFormat,
} from "src/utils/utilities";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const ScrollDialog = ({ isOpen, setIsOpenCallback, data }) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsOpenCallback(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [isOpen]);

  if (!isOpen) return <Fragment />;
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent dividers={true} style={{ width: "600px" }}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
          component={"span"}
        >
          <List dense={false}>
            {data.map((item, index) => (
              <Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={
                      <text style={{ fontWeight: "bold", fontSize: "20px" }}>
                        เลขที่บิล {billNumberFormat(item.billId)}
                      </text>
                    }
                    secondary={
                      <Fragment>
                        <div className={classes.item}>
                          <text
                            style={{ marginTop: "3px", marginBottom: "3px" }}
                          >
                            <text style={{ fontWeight: "bold" }}>ร้าน </text>
                            {item.merchantName}
                          </text>
                          <text
                            style={{ marginTop: "3px", marginBottom: "3px" }}
                          >
                            <text style={{ fontWeight: "bold" }}>วันที่ </text>
                            {formatDate(item.billUpdatedDate)}
                          </text>
                        </div>
                        <div>
                          <p style={{ marginTop: "3px", marginBottom: "3px" }}>
                            <text style={{ fontWeight: "bold" }}>
                              จำนวนเงิน
                            </text>
                            {` ${numberWithCommas(
                              item.total_paid_per_bill
                            )} บาท`}
                          </p>
                        </div>
                        <div>
                          <p style={{ marginTop: "3px", marginBottom: "3px" }}>
                            <text style={{ fontWeight: "bold" }}>ราคาขาย </text>
                            {`${numberWithCommas(item.price_order)} บาท`}
                          </p>
                        </div>
                        <div>
                          <p style={{ marginTop: "3px", marginBottom: "3px" }}>
                            <text style={{ fontWeight: "bold" }}>จำนวน </text>
                            {`${item.amount} `}
                            {`${item.order_unit}`}
                          </p>
                        </div>
                      </Fragment>
                    }
                  />
                </ListItem>
                {index != data.length - 1 && <Divider component="li" />}
              </Fragment>
            ))}
          </List>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>ปิด</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScrollDialog;
