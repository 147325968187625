import { put, call, take } from "redux-saga/effects";
import {
  getMerchant,
  MERCHANT_GET,
  updateMerchant,
  MERCHANT_CREATE,
  MERCHANT_EDIT,
  MERCHANT_GET_BY_ID,
  updateMerchants,
  MERCHANT_DELETE,
} from "src/actions/MerchantAction";
import {
  apiGetMerchant,
  apiCreateMerchant,
  apiEditMerchant,
  apiGetMerchantByID,
  apiDeleteMerchant,
} from "src/api/MerchantApi";
import { handleStatusError, handleStatusSuccess } from ".";
import { hideSpinner, showSpinner } from "src/components";

export function* workerGetMerchant(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetMerchant, action?.payload);
    if (data.message) {
      yield put(updateMerchants(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetMerchant() {
  while (true) {
    const action = yield take(MERCHANT_GET);
    yield call(workerGetMerchant, action);
  }
}

export function* workerCreateMerchant(action) {
  try {
    yield call(showSpinner);
    const navigate = action.navigate;
    const { data } = yield call(apiCreateMerchant, action?.payload);
    if (data.message) {
      yield call(handleStatusSuccess, "Create Merchant Success");
      navigate(`/app/merchant`);
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherCreateMerchant() {
  while (true) {
    const action = yield take(MERCHANT_CREATE);
    yield call(workerCreateMerchant, action);
  }
}

export function* workerEditMerchant(action) {
  try {
    yield call(showSpinner);
    const navigate = action.navigate;
    const { data } = yield call(apiEditMerchant, action?.payload, action?.id);
    if (data.message) {
      yield call(handleStatusSuccess, "Update Merchant Success");
      navigate(`/app/merchant`);
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherEditMerchant() {
  while (true) {
    const action = yield take(MERCHANT_EDIT);
    yield call(workerEditMerchant, action);
  }
}

export function* workerGetMerchantByID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetMerchantByID, action?.payload);
    if (data.message) {
      yield put(updateMerchant(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetMerchantByID() {
  while (true) {
    const action = yield take(MERCHANT_GET_BY_ID);
    yield call(workerGetMerchantByID, action);
  }
}

export function* workerDeleteMerchantByID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiDeleteMerchant, action?.payload);
    if (data.message) {
      yield call(handleStatusSuccess, "Delete Merchant Success");
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherDeleteMerchantByID() {
  while (true) {
    const action = yield take(MERCHANT_DELETE);
    yield call(workerDeleteMerchantByID, action);
  }
}
