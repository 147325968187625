import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";

import { createStore, applyMiddleware } from "redux";
import rootReducer from "src/reducers";
import rootSaga from "src/sagas";

import createSagaMiddleware from "redux-saga";
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);
export default store;

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();
