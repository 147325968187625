import { put, call, take } from "redux-saga/effects";
import {
  INVOICE_CREATE,
  INVOICE_GET_LIST,
  updateInvoiceList,
  DELETE_INVOICE_BY_ID,
  INVOICE_PRINT,
} from "src/actions/InvoiceAction";
import {
  apiCreateInvoice,
  apiGetInvoiceByID,
  apiGetInvoiceByCondition,
  apiDeleteInvoiceByID,
} from "src/api/InvoiceApi";
import { handleStatusError, handleStatusSuccess } from ".";
import { hideSpinner, showSpinner } from "src/components";
import { invoicePDF } from "src/utils/pdf";

export function* workerCreateInvoice(action) {
  try {
    yield call(showSpinner);

    const { data } = yield call(apiCreateInvoice, action.payload);
    if (data.message) {
      const invoice = yield call(apiGetInvoiceByID, {
        invoiceID: data.message,
      });
      yield call(invoicePDF, invoice.data.message);
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherCreateInvoice() {
  while (true) {
    const action = yield take(INVOICE_CREATE);
    yield call(workerCreateInvoice, action);
  }
}

export function* workergGetInvoiceList(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetInvoiceByCondition, action.payload);
    if (data.message) {
      yield put(updateInvoiceList(data.message));
    } else {
      yield put(updateInvoiceList([]));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetInvoiceList() {
  while (true) {
    const action = yield take(INVOICE_GET_LIST);
    yield call(workergGetInvoiceList, action);
  }
}

export function* workergDeleteInvoiceByID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiDeleteInvoiceByID, action.payload);
    if (data.message) {
      yield call(handleStatusSuccess, "Delete Invoice success");
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherDeleteInvoiceByID() {
  while (true) {
    const action = yield take(DELETE_INVOICE_BY_ID);
    yield call(workergDeleteInvoiceByID, action);
  }
}

export function* workerPrintInvoice(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetInvoiceByID, {
      invoiceID: action.payload.invoiceID,
    });
    if (data.message) {
      yield call(invoicePDF, data.message);
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherPrintInvoice() {
  while (true) {
    const action = yield take(INVOICE_PRINT);
    yield call(workerPrintInvoice, action);
  }
}
