import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { DoughnutChart } from "src/components";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow:
      "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
    textAlign: "start",
  },
  chart: {
    display: "flex",
    justifyContent: "center",
  },
}));

const SaleMerchant = ({ title, dataChart }) => {
  const classes = useStyles();
  const [lebels, setLebels] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    let tempLebels = [];
    let tempData = [];
    dataChart.map((item) => {
      tempLebels.push(item.merchantName);
      tempData.push(item.saleAmount);
    });
    setLebels(tempLebels);
    setData(tempData);
  }, [dataChart]);

  return (
    <Card sx={{ minWidth: 275 }} className={classes.root}>
      <CardContent>
        <Typography sx={{ fontSize: 24 }} gutterBottom>
          {title}
        </Typography>
        <div className={classes.chart}>
          <DoughnutChart dataSet={data} labels={lebels} label={"ยอดเงิน"} />
        </div>
      </CardContent>
    </Card>
  );
};

export default SaleMerchant;
