import React from "react";
import { makeStyles } from "@material-ui/core";
import PCL_LogoNav from "src/assets/images/pcl_logo_nav.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 150,
    height: 80,
  },
}));

const Logo = ({ className }) => {
  const classes = useStyles();

  return <img className={classes.root} alt="Logo" src={PCL_LogoNav} />;
};

export default Logo;
