import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Typography,
  makeStyles,
  Box,
  Grid,
  TextField,
  Modal,
  Select,
  MenuItem,
} from "@material-ui/core";
import { MainButton, AutoCompleteDropdown } from "src/components";
import {
  AddOutlined as AddOutlinedIcon,
  AddShoppingCartOutlined as AddShoppingCartOutlinedIcon,
} from "@material-ui/icons";
import { UNIT } from "src/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    backgroundColor: "#FFFFFF",
    border: "2px solid #FFF",
    boxShadow: 24,
    padding: 30,
    borderRadius: "20px",
    outline: "none",
  },
  image: {
    width: 120,
    height: 120,
  },
  button: {
    minWidth: "120px",
    margin: 10,
    "&:hover": {
      minWidth: "120px",
    },
  },
}));

const AddBillItemModal = ({
  isOpen,
  setIsModalOpen,
  title,
  addBillItem,
  catalogs,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(isOpen);
  const [item, setItem] = useState("");
  const [freeItem, setFreeItem] = useState(0);
  const [priceOrder, setPriceOrder] = useState("");
  const [amount, setAmount] = useState("");
  const [unit, setUnit] = useState("");
  const [isItemError, setIsItemError] = useState(false);
  const [isPriceError, setIsPriceError] = useState(false);
  const [isAmountError, setIsAmountError] = useState(false);
  const [isUnitError, setIsUnitError] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const validateInput = () => {
    if (priceOrder !== "") {
      setIsPriceError(false);
    } else {
      setIsPriceError(true);
    }
    if (amount !== "") {
      setIsAmountError(false);
    } else {
      setIsAmountError(true);
    }
    if (unit !== "") {
      setIsUnitError(false);
    } else {
      setIsUnitError(true);
    }
    if (item !== "") {
      setIsItemError(false);
    } else {
      setIsItemError(true);
    }
  };

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    validateInput();
  }, [amount, unit, item, priceOrder]);

  const onSubmit = () => {
    if (!isAmountError && !isItemError && !isPriceError && !isUnitError) {
      let total = priceOrder * amount;
      let newItem = {
        ...item,
        merchandiseID: item.id,
        merchandiseName: item.name,
      };
      let data = {
        ...newItem,
        priceOrder,
        amount,
        unit,
        totalPrice: total,
        discount1: 0,
        discount2: 0,
        isFree: parseFloat(freeItem) !== 0 ? true : false,
        freeAmount: parseFloat(freeItem),
      };
      addBillItem(data);
      handleClose();
      setUnit("");
      setPriceOrder("");
      setItem("");
      setIsAmountError(false);
      setIsPriceError(false);
      setIsUnitError(false);
      setIsItemError(false);
      setFreeItem(0);
    } else {
      console.log("Error");
      // console.log("isAmountError", isAmountError);
      // console.log("isItemError", isItemError);
      // console.log("isPriceError", isPriceError);
      // console.log("isUnitError", isUnitError);
    }
  };

  return (
    <Fragment>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.root}>
          <Box display={"flex"} justifyContent="space-between" mb={2}>
            <Typography
              id="modal-modal-title"
              variant="h3"
              style={{ paddingTop: "4px", paddingBottom: "15px" }}
            >
              เพิ่มรายการ
            </Typography>
            <button type="submit" form="hook-form" name="addCatalog">
              เพิ่มสินค้า
            </button>
            {/* <MainButton
              type="submit"
              title="เพิ่มสินค้า"
              name="addCatalog"
              startIcon={<AddShoppingCartOutlinedIcon />}
              onClick={() => addCatalog()}
            /> */}
          </Box>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={2}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ paddingRight: "1rem" }}
              >
                รายการสินค้า
              </Typography>
            </Grid>
            <Grid container item xs={10} spacing={1}>
              <Grid item xs={10}>
                {catalogs.length !== 0 && (
                  <AutoCompleteDropdown
                    label={"รายการสินค้า"}
                    setValue={(e) => {
                      setItem(e);
                      setPriceOrder(e.price);
                      setUnit(e.unit);
                    }}
                    options={catalogs}
                  />
                )}
              </Grid>
              <Grid item xs={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 0 } }}
                  type="number"
                  label="ของแถม"
                  variant="outlined"
                  value={freeItem}
                  onChange={(e) => setFreeItem(e.currentTarget.value)}
                />
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ paddingRight: "1rem" }}
              >
                ราคา
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                type="number"
                label="ราคา"
                variant="outlined"
                size="small"
                value={priceOrder}
                onChange={(e) => setPriceOrder(e.currentTarget.value)}
                style={{ minWidth: "25rem" }}
                error={isPriceError}
                helperText={isPriceError && "Price Required"}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ paddingRight: "1rem" }}
              >
                จำนวน
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { min: 0 } }}
                type="number"
                onChange={(e) => setAmount(e.currentTarget.value)}
                label="จำนวน"
                variant="outlined"
                size="small"
                style={{ minWidth: "25rem" }}
                error={isAmountError}
                helperText={isAmountError && "Amount Required"}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ paddingRight: "1rem" }}
              >
                หน่วย
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Select
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={unit}
                label="หน่วย"
                fullWidth
                onChange={(e) => setUnit(e.target.value)}
              >
                {UNIT.map((unit) => (
                  <MenuItem value={unit} key={unit}>
                    {unit}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Box textAlign="center" mt={3}>
            <MainButton
              type="button"
              title="เพิ่มรายการ"
              onClick={() => onSubmit()}
              startIcon={<AddOutlinedIcon />}
            />
            <MainButton
              type="button"
              title="ยกเลิก"
              onClick={() => setIsModalOpen()}
            />
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default AddBillItemModal;
