import { apiCall } from "src/utils/axios";

export const apiCreateInvoice = (payload) => {
  return apiCall("POST", "api/v1/invoice/create", payload, null);
};

export const apiGetInvoiceByID = (payload) => {
  return apiCall("GET", "api/v1/invoice/id", null, payload);
};

export const apiGetInvoiceByCondition = (payload) => {
  return apiCall("GET", "api/v1/invoice", null, payload);
};

export const apiDeleteInvoiceByID = (payload) => {
  return apiCall("POST", `api/v1/invoice/delete/${payload}`, null, null);
};
