import { combineReducers } from "redux";

import { MerchantReducer, MERCHANT_STATE } from "./MerchantReducer";
import { CatalogReducer, CATALOG_STATE } from "./CatalogReducer";
import { BillReducer, BILL_STATE } from "./BillReducer";
import { InvoiceReducer, INVOICE_STATE } from "./InvoiceReducer";
import { DashboardReducer, DASHBOARD_STATE } from "./DashboardReducer";

const rootReducer = combineReducers({
  [MERCHANT_STATE]: MerchantReducer,
  [CATALOG_STATE]: CatalogReducer,
  [BILL_STATE]: BillReducer,
  [INVOICE_STATE]: InvoiceReducer,
  [DASHBOARD_STATE]: DashboardReducer,
});

export default rootReducer;
