import {
  UPDATE_DASHBOARD_SUMMARY,
  UPDATE_DASHBOARD_YEARLY_SUMMARY,
  UPDATE_DASHBOARD_TOP_PRODUCT_DETAIL,
  UPDATE_DASHBOARD_OVERVIEW,
  UPDATE_DASHBOARD_SUMMART_EACH_MONTH,
} from "src/actions/DashboardAction";

export const DASHBOARD_STATE = "dashboard";
const INITIAL_STATE = {
  NumberBill: 0,
  TotalSalePerMonth: 0,
  SalesGroupByMerchant: [],
  MonthSale: [],
  TopProduct: [],
  TopProductDetail: [],
  Overview: {},
  SummaryEachMonth: {},
};

export const DashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_SUMMARY:
      return {
        ...state,
        NumberBill: action.payload.numberBill,
        TotalSalePerMonth: action.payload.totalSalesPerMonth,
        SalesGroupByMerchant: action.payload.salesGroupbyMerchant,
      };
    case UPDATE_DASHBOARD_YEARLY_SUMMARY:
      return {
        ...state,
        NumberBill: action.payload.numberBill,
        TotalSalePerMonth: action.payload.totalSalesPerMonth,
        SalesGroupByMerchant: action.payload.salesGroupbyMerchant,
        MonthSale: action.payload.monthSale,
        TopProduct: action.payload.topProduct,
      };
    case UPDATE_DASHBOARD_TOP_PRODUCT_DETAIL:
      return {
        ...state,
        TopProductDetail: action.payload,
      };
    case UPDATE_DASHBOARD_OVERVIEW:
      return {
        ...state,
        Overview: action.payload,
      };
    case UPDATE_DASHBOARD_SUMMART_EACH_MONTH:
      return {
        ...state,
        SummaryEachMonth: action.payload,
      };
    default:
      return state;
  }
};

export const DashboardSelector = (state) => state[DASHBOARD_STATE];
