import { UPDATE_INVOICE_LIST } from "src/actions/InvoiceAction";

export const INVOICE_STATE = "invoice";
const INITIAL_STATE = {
  Invoices: [],
};

export const InvoiceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_INVOICE_LIST:
      return { ...state, Invoices: action.payload };
    default:
      return state;
  }
};

export const InvoiceSelector = (state) => state[INVOICE_STATE];
