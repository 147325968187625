import {
  UPDATE_BILL_REQUEST_NEW_BILL_ID,
  BILL_UPDATE_LIST,
  BILL_UPDATE,
} from "src/actions/BillAction";

export const BILL_STATE = "bill";
const INITIAL_STATE = {
  LatestBill: 0,
  BillsWithMerchants: [],
  Bill: {},
};

export const BillReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_BILL_REQUEST_NEW_BILL_ID:
      return { ...state, LatestBill: action.payload };
    case BILL_UPDATE_LIST:
      return { ...state, BillsWithMerchants: action.payload };
    case BILL_UPDATE:
      return { ...state, Bill: action.payload[0] };
    default:
      return state;
  }
};

export const BillSelector = (state) => state[BILL_STATE];
