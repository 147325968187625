import { put, call, take } from "redux-saga/effects";
import {
  DASHBOARD_GET_SUMMARY,
  updateDashboardSummary,
  DASHBOARD_YEARLY_GET_SUMMARY,
  updateDashboardYearlySummary,
  DASHBOARD_TOP_PRODUCT_DETAIL,
  updateTopProductDetail,
  DASHBOARD_OVERVIEW,
  updateOverview,
  updateSummaryEachMonth,
  DASHBOARD_SUMMART_EACH_MONTH,
} from "src/actions/DashboardAction";
import {
  apiGetDashboardSummary,
  apiGetYearlyDashboardSummary,
  apiGetTopProductDetail,
  apiGetSummaryEachMonth,
  apiGetOverview,
} from "src/api/DashboardApi";
import { handleStatusError, handleStatusSuccess } from ".";
import { hideSpinner, showSpinner } from "src/components";

export function* workerGetDashboardSummary(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetDashboardSummary, action.payload);
    if (data.message) {
      yield put(updateDashboardSummary(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetDashboardSummary() {
  while (true) {
    const action = yield take(DASHBOARD_GET_SUMMARY);
    yield call(workerGetDashboardSummary, action);
  }
}

export function* workerGetYearlyDashboardSummary(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetYearlyDashboardSummary, action.payload);
    if (data.message) {
      yield put(updateDashboardYearlySummary(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetYearlyDashboardSummary() {
  while (true) {
    const action = yield take(DASHBOARD_YEARLY_GET_SUMMARY);
    yield call(workerGetYearlyDashboardSummary, action);
  }
}

export function* workerGetTopProductDetail(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetTopProductDetail, action.payload);
    if (data.message) {
      yield put(updateTopProductDetail(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetTopProductDetail() {
  while (true) {
    const action = yield take(DASHBOARD_TOP_PRODUCT_DETAIL);
    yield call(workerGetTopProductDetail, action);
  }
}

export function* workerGetOverview(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetOverview, action.payload);
    if (data.message) {
      yield put(updateOverview(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetOverview() {
  while (true) {
    const action = yield take(DASHBOARD_OVERVIEW);
    yield call(workerGetOverview, action);
  }
}

export function* workerGetSummaryEachMonth(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetSummaryEachMonth, action.payload);
    if (data.message) {
      yield put(updateSummaryEachMonth(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetSummaryEachMonth() {
  while (true) {
    const action = yield take(DASHBOARD_SUMMART_EACH_MONTH);
    yield call(workerGetSummaryEachMonth, action);
  }
}
