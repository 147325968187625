import axios from "axios";
import config from "../config";
import _ from "lodash";
import { getAccessToken } from "src/utils/session";

const axiosInstance = axios.create({
  baseURL: config().API_ENDPOINT,
});

export const apiCall = (method, uri, data, queryParams) => {
  let params = [];
  _.forEach(queryParams || {}, (val, key) =>
    params.push(`${key}=${encodeURIComponent(val)}`)
  );

  let url = `${config().API_ENDPOINT}${uri}`;
  if (params.length > 0) {
    url = `${url}?${params.join("&")}`;
  }

  let headers = {
    "Content-Type": "application/json",
    // Authorization:
    //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDE3MTY3MDgsIm5hbWUiOiJhZG1pbiIsIm9yaWdfaWF0IjoxNjM5MTI0NzA4fQ._B5VZlyiKtbrcIeJlcT9CyFvIO5GQl3KyxEE-P0dEkI",
    Authorization: getAccessToken(), //? 'Bearer ' + getAccessToken() : undefined ,
  };

  return axiosInstance({
    method: method,
    url: url,
    data: data,
    headers: headers,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 401 && uri !== "api/login") {
        window.location = window.location.origin + "/login";
        throw new Error("Plese Login");
      }
      throw new Error(err);
    });
};

export const apiUpload = (method, uri, data, queryParams) => {
  let params = [];
  _.forEach(queryParams || {}, (val, key) =>
    params.push(`${key}=${encodeURIComponent(val)}`)
  );

  let url = `${config().API_ENDPOINT}${uri}`;
  if (params.length > 0) {
    url = `${url}?${params.join("&")}`;
  }

  let headers = {
    "Content-Type": "multipart/form-data",
    // Authorization:
    //   "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDE3MTY3MDgsIm5hbWUiOiJhZG1pbiIsIm9yaWdfaWF0IjoxNjM5MTI0NzA4fQ._B5VZlyiKtbrcIeJlcT9CyFvIO5GQl3KyxEE-P0dEkI",
    Authorization: getAccessToken(), //? 'Bearer ' + getAccessToken() : undefined ,
  };
  return axiosInstance({
    method: method,
    url: url,
    data: data,
    headers: headers,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      if (err.response.status === 401 && uri !== "api/login") {
        window.location = window.location.origin + "/login";
        throw new Error("Plese Login");
      }
      throw new Error(err);
    });
};
