import React from "react";
import { Typography, makeStyles, Grid, TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "start",
    padding: theme.spacing(2, 0),
  },
  form: {
    alignItems: "center",
  },
}));

const CreateMerchantForm = ({ formMethods, merchant }) => {
  const {
    formState: { errors },
    control,
  } = formMethods;
  const classes = useStyles();
  const inputLabelProp = {
    shrink: true,
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid container item xs={8} spacing={2} className={classes.form}>
        <Grid item xs={4}>
          <Typography variant={"h4"}>ชื่อสินค้า :</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="name"
            control={control}
            defaultValue={merchant.name || ""}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <TextField
                InputLabelProps={inputLabelProp}
                label="ชื่อสินค้า"
                variant="outlined"
                fullWidth
                helperText={!!errors.name && "Required"}
                error={!!errors.name}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"h4"}>ที่อยู่ :</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="address"
            control={control}
            defaultValue={merchant.address || ""}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <TextField
                InputLabelProps={inputLabelProp}
                label="ที่อยู่"
                variant="outlined"
                helperText={!!errors.address && "Required"}
                fullWidth
                error={!!errors.address}
                {...field}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateMerchantForm;
