import { all, fork, call } from "redux-saga/effects";
import { ERROR_CONNECTION_TIMEOUT, ERROR_SESSION_LOST } from "../api/apiCall";
import {
  watcherGetMerchant,
  watcherCreateMerchant,
  watcherGetMerchantByID,
  watcherEditMerchant,
  watcherDeleteMerchantByID,
} from "./MerchantSaga";
import {
  watcherGetCatalogList,
  watcherGetCatalogByID,
  watcherEditCatalogByID,
  watcherDeleteCatalogByID,
  watcherCreaetCatalogByID,
  watcherUploadImageCatalog,
} from "./CatalogSaga";
import {
  watcherSaveBill,
  watcherRequestNewBillID,
  watcherGetBillsWithMerchants,
  watcherGetBillsByID,
  watcherGetBillsByMerchantID,
  watcherDeleteBillsByID,
} from "./BillSaga";
import {
  watcherCreateInvoice,
  watcherGetInvoiceList,
  watcherDeleteInvoiceByID,
  watcherPrintInvoice,
} from "./InvoiceSaga";
import {
  watcherGetDashboardSummary,
  watcherGetYearlyDashboardSummary,
  watcherGetTopProductDetail,
  watcherGetOverview,
  watcherGetSummaryEachMonth,
} from "./DashboardSaga";
import { watcherLogin } from "./AuthSaga";
import { showAlert } from "src/components";

export function* handleStatusError(error) {
  if (error?.status == 422) {
    error = new Error("GraphQL Error (422)");
  }

  if (error?.message == ERROR_SESSION_LOST) {
    // yield call(showAlert, getWord('operation_false'), 'Session Lost กรุณา Login ใหม่')
    // yield call(workerAutoRouting)
  } else if (error?.message == ERROR_CONNECTION_TIMEOUT) {
    // yield call(showAlert, getWord('error_topic'), getWord('connect_timeout'))
  } else {
    yield call(showAlert, "Something went wrong", error?.message);
  }
}

export function* handleStatusSuccess(message) {
  yield call(showAlert, "Success", message);
}

export default function* rootSaga() {
  yield all([
    fork(watcherGetMerchant),
    fork(watcherCreateMerchant),
    fork(watcherGetMerchantByID),
    fork(watcherEditMerchant),
    fork(watcherDeleteMerchantByID),
    fork(watcherGetCatalogList),
    fork(watcherGetCatalogByID),
    fork(watcherEditCatalogByID),
    fork(watcherDeleteCatalogByID),
    fork(watcherCreaetCatalogByID),
    fork(watcherSaveBill),
    fork(watcherRequestNewBillID),
    fork(watcherGetBillsWithMerchants),
    fork(watcherGetBillsByID),
    fork(watcherGetBillsByMerchantID),
    fork(watcherCreateInvoice),
    fork(watcherGetInvoiceList),
    fork(watcherDeleteInvoiceByID),
    fork(watcherDeleteBillsByID),
    fork(watcherGetDashboardSummary),
    fork(watcherPrintInvoice),
    fork(watcherLogin),
    fork(watcherUploadImageCatalog),
    fork(watcherGetYearlyDashboardSummary),
    fork(watcherGetTopProductDetail),
    fork(watcherGetOverview),
    fork(watcherGetSummaryEachMonth),
  ]);
}
