import { put, call, take } from "redux-saga/effects";
import { AUTH_LOGIN } from "src/actions/AuthAction";
import { apiLogin } from "src/api/AuthenticationApi";
import { handleStatusError, handleStatusSuccess } from ".";
import { hideSpinner, showSpinner } from "src/components";
import { printPDF } from "src/utils/pdf";
import { setAccessToken } from "src/utils/session";

export function* workerLogin(action) {
  const navigate = action.navigate;

  try {
    yield call(showSpinner);
    const response = yield call(apiLogin, action.payload);
    if (response.data) {
      const { token } = response.data;
      yield call(setAccessToken, token);
      setTimeout(function () {
        navigate("/app/dashboard");
        // yield call(hideSpinner);
      }, 2000);
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherLogin() {
  while (true) {
    const action = yield take(AUTH_LOGIN);
    yield call(workerLogin, action);
  }
}
