import { CATALOG_UPDATE_LIST, CATALOG_UPDATE } from "src/actions/CatalogAction";

export const CATALOG_STATE = "catalog";
const INITIAL_STATE = {
  Catalogs: [],
  Catalog: {},
};

export const CatalogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CATALOG_UPDATE_LIST:
      return { ...state, Catalogs: action.payload };
    case CATALOG_UPDATE:
      return { ...state, Catalog: action.payload };
    default:
      return state;
  }
};

export const CatalogSelector = (state) => state[CATALOG_STATE];
