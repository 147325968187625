import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const UploadButton = ({
  className,
  title,
  onClick,
  href,
  type,
  isDisable,
  startIcon,
  color,
  id,
  name,
}) => {
  const classes = useStyles();

  const dynamicProps = () => {
    const props = {};
    if (!!href && href.length) {
      props.to = href;
    }

    return props;
  };

  return (
    <Button
      className={clsx(classes.root, className)}
      onClick={onClick}
      style={{ textTransform: "none" }}
      component={"span"}
      startIcon={startIcon}
      type={type}
      {...dynamicProps()}
      disabled={isDisable}
      id={id}
      name={name}
    >
      {title}
    </Button>
  );
};

export default UploadButton;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "5px",
    minWidth: "120px",
    minHeight: "2.5rem",
    // maxWidth: "138px",
    // minHeight: "2.5rem",
    alignItems: "center",
    borderRadius: "4px",
    border: theme.palette.btn.mainBorder,
    backgroundColor: theme.palette.btn.main,
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: 500,
    fontStretch: "condensed",
    fontStyle: "normal",
    lineHeight: 1.64,
    letterSpacing: "normal",
    textAlign: "center",
    color: theme.palette.text.btnMain,
    boxShadow: "0 1px 2px 0 rgba(63, 63, 68, 0.15)",
    "&:hover": {
      border: theme.palette.btn.mainBorderHover,
      opacity: 1,
      cursor: "pointer",
      color: theme.palette.text.btnMainHover,
      minWidth: "120px",
      minHeight: "2.5rem",
      maxWidth: "138px",
      borderRadius: "4px",
      lineHeight: 1.64,
      boxShadow: "0 1px 2px 0 rgba(63, 63, 68, 0.15)",
    },
  },
}));
