const ButtonColor = {
  MAIN: "main",
  WHITE: "white",
  YELLOW: "yellow",
  ORAGNGE: "orange",
  RED: "red",
  GREEN: "green",
};

export default ButtonColor;
