import React from "react";
import { makeStyles } from "@material-ui/core";
import Colors from "./Colors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.text.dividerGray,
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
    height: 1,
    border: `1px solid ${Colors.DIVIDER_GRAY}`,
  },
}));

const DividerCustom = () => {
  const classes = useStyles();
  return <hr className={classes.root}></hr>;
};

export default DividerCustom;
