import React from "react";
import { Chart, Bar } from "react-chartjs-2";

const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const Multitype = ({ params }) => {
  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        label: "2022",
        backgroundColor: "rgb(75, 192, 192)",
        data: params["2022"],
        borderColor: "white",
        borderWidth: 2,
      },
      {
        type: "bar",
        label: "2023",
        backgroundColor: "rgb(53, 162, 235)",
        data: params["2023"],
        borderColor: "white",
        borderWidth: 2,
      },
      {
        type: "bar",
        label: "2024",
        backgroundColor: "rgb(53, 102, 235)",
        data: params["2024"],
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };

  return <Chart type="bar" data={data} />;
  // return <Bar options={options} data={data} />;
};
export default Multitype;
