import React, { useState, useEffect, Fragment } from "react";
import {
  Typography,
  makeStyles,
  Grid,
  TextField,
  Input,
  Select,
  Box,
  MenuItem,
} from "@material-ui/core";
import { UploadButton } from "src/components";
import { CloudUploadOutlined as CloudUploadOutlinedIcon } from "@material-ui/icons";
import { Controller } from "react-hook-form";
import { UNIT } from "src/constants";
import { uploadImageCatalog } from "src/actions/CatalogAction";
import { useDispatch, useSelector } from "src/store";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "start",
    padding: theme.spacing(2, 0),
  },
  form: {
    alignItems: "center",
  },
  sectionImage: {
    padding: theme.spacing(2, 2),
  },
}));

const CreateCatalogForm = ({ formMethods, catalog }) => {
  const dispatch = useDispatch();
  const {
    formState: { errors },
    control,
    setValue,
  } = formMethods;
  const classes = useStyles();
  const inputLabelProp = {
    shrink: true,
  };
  const [unit, setUnit] = useState("");
  useEffect(() => {
    setUnit(catalog.unit);
  }, [catalog]);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
    setValue("unit", event.target.value);
  };

  const onChange = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    const req = {
      catalogID: catalog.id,
      data: formData,
    };
    dispatch(uploadImageCatalog(req));
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid container item xs={8} spacing={2} className={classes.form}>
        <Grid item xs={4}>
          <Typography variant={"h4"}>ชื่อสินค้า :</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="name"
            control={control}
            defaultValue={catalog.name}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <TextField
                InputLabelProps={inputLabelProp}
                label="ชื่อสินค้า"
                variant="outlined"
                fullWidth
                helperText={!!errors.name && "Required"}
                error={!!errors.name}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"h4"}>ราคา :</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="price"
            control={control}
            defaultValue={catalog.price}
            rules={{ required: "Required" }}
            render={({ field }) => (
              <TextField
                InputLabelProps={inputLabelProp}
                label="ราคา"
                type="number"
                variant="outlined"
                helperText={!!errors.price && "Required"}
                fullWidth
                error={!!errors.price}
                {...field}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"h4"}>หน่วย :</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="unit"
            control={control}
            defaultValue=""
            // rules={{ required: "Required" }}
            render={({ field }) => (
              <Select
                value={unit}
                variant="outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="หน่วย"
                fullWidth
                onChange={handleUnitChange}
              >
                {UNIT.map((unit) => (
                  <MenuItem value={unit}>{unit}</MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant={"h4"}> รูปภาพ :</Typography>
        </Grid>
        <Grid item xs={8}>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              style={{ display: "none" }}
              onChange={onChange}
            />
            <UploadButton
              variant="contained"
              type={"span"}
              startIcon={<CloudUploadOutlinedIcon />}
              title={"Upload"}
            ></UploadButton>
          </label>
        </Grid>
        <Box className={classes.sectionImage}>
          {catalog.image !== "" ? (
            <img
              className={classes.root}
              alt="Logo"
              src={catalog.image}
              height="300"
            />
          ) : (
            <Fragment />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CreateCatalogForm;
