import React, { useEffect, useState } from "react";
import {   Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Barchart } from "src/components";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow:
      "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
    textAlign: "start",
  },
}));

const BillMerchant = ({ title, dataChart }) => {
  const classes = useStyles();
  const [lebels, setLebels] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    let tempLebels = [];
    let tempData = [];
    dataChart.map((item) => {
      tempLebels.push(item.merchantName);
      tempData.push(item.countBill);
    });
    setLebels(tempLebels);
    setData(tempData);
  }, [dataChart]);

  return (
    <Card sx={{ minWidth: 275 }} className={classes.root}>
      <CardContent>
        <Typography sx={{ fontSize: 24 }} gutterBottom>
          {title}
        </Typography>
        <Barchart dataSet={data} labels={lebels} label={"จำนวนบิล"} />
      </CardContent>
    </Card>
  );
};

export default BillMerchant;
