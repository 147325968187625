import React, { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import { SplashScreen } from "../components";
import { apiLogin, apiGetUser } from "../api/AuthenticationApi";
import { setAccessToken } from "../utils/session";
import { authLogin } from "src/actions/AuthAction";
import { Users } from "react-feather";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (username, password) => {

    const response = await apiLogin({
      username,
      password,
    });
    const { token, user } = response.data;
    setAccessToken(token);

    // dispatch({
    //   type: "LOGIN",
    //   payload: {
    //     user,
    //   },
    // });
  };

  const logout = () => {
    setAccessToken(null);
    dispatch({ type: "LOGOUT" });
  };

  const register = async (email, name, password) => {
    // const response = await axios.post("/api/account/register", {
    //   email,
    //   name,
    //   password,
    // });
    // const { accessToken, user } = response.data;
    // window.localStorage.setItem("accessToken", accessToken);
    // dispatch({
    //   type: "REGISTER",
    //   payload: {
    //     user,
    //   },
    // });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        if (accessToken && isValidToken(accessToken)) {
          setAccessToken(accessToken);

          const response = await apiGetUser();
          const user = response.data?.getAdmin;

          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
