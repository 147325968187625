import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow:
      "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
    textAlign: "start",
  },
  photo: {
    width: 65,
  },
  icon: {
    textAlignLast: "center",
    alignSelf: "center",
  },
}));

const MyCard = ({ title, content, icon }) => {
  const classes = useStyles();

  return (
    <Card sx={{ minWidth: 275 }} className={classes.root}>
      <CardContent>
        <Grid container>
          <Grid item xs={9}>
            <Typography sx={{ fontSize: 24 }} gutterBottom>
              {title}
            </Typography>
            <Typography color="text.secondary">{content}</Typography>
          </Grid>
          <Grid item xs={3} className={classes.icon}>
            <img className={classes.photo} alt="Logo" src={icon} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MyCard;
