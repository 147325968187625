import dayjs from "dayjs";
import "dayjs/locale/th";

dayjs.locale("th");

export const toThaiString = (price) => {
  let temp = parseFloat(price);
  return temp.toLocaleString("th-TH");
};

export const formatDate = (dateString) => {
  return dayjs(dateString).locale("th").add(543, "year").format("DD/MM/YYYY");
};

export const formatDateWithTime = (dateString) => {
  return dayjs(dateString)
    .locale("th")
    .add(543, "year")
    .format("DD/MM/YYYY เวลา HH:mm:ss");
};

export const monthName = (intMonth) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return months[intMonth];
};

const listException = [".1", ".2", ".3", ".4", ".5", ".6", ".7", ".8", ".9"];

export const numberWithCommas = (price) => {
  const dot = price.toString().split(".");
  const interger = dot[0];
  const floatingPoint = dot[1] === undefined ? ".00" : "." + dot[1].slice(0, 2);
  if (listException.includes(floatingPoint)) {
    return interger.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + floatingPoint + "0";
  }
  return interger.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + floatingPoint;
};

export const numberPercent = (p) => {
  let pp = parseFloat(p);
  return pp.toFixed(2) + "%";
};

export const numberWithCommasInterger = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const BAHTTEXT = (num, suffix) => {
  if (typeof suffix === "undefined") {
    suffix = "บาทถ้วน";
  }

  num = num || 0;
  num = num.toString().replace(/[, ]/g, ""); // remove commas, spaces

  if (isNaN(num) || Math.round(parseFloat(num) * 100) / 100 === 0) {
    return "ศูนย์บาทถ้วน";
  } else {
    var t = ["", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน"],
      n = [
        "",
        "หนึ่ง",
        "สอง",
        "สาม",
        "สี่",
        "ห้า",
        "หก",
        "เจ็ด",
        "แปด",
        "เก้า",
      ],
      len,
      digit,
      text = "",
      parts,
      i;

    if (num.indexOf(".") > -1) {
      // have decimal

      /*
       * precision-hack
       * more accurate than parseFloat the whole number
       */

      parts = num.toString().split(".");

      num = parts[0];
      parts[1] = parseFloat("0." + parts[1]);
      parts[1] = (Math.round(parts[1] * 100) / 100).toString(); // more accurate than toFixed(2)
      parts = parts[1].split(".");

      if (parts.length > 1 && parts[1].length === 1) {
        parts[1] = parts[1].toString() + "0";
      }

      num = parseInt(num, 10) + parseInt(parts[0], 10);

      /*
       * end - precision-hack
       */
      text = num ? BAHTTEXT(num) : "";

      if (parseInt(parts[1], 10) > 0) {
        text = text.replace("ถ้วน", "") + BAHTTEXT(parts[1], "สตางค์");
      }

      return text;
    } else {
      if (num.length > 7) {
        // more than (or equal to) 10 millions

        var overflow = num.substring(0, num.length - 6);
        var remains = num.slice(-6);
        return (
          BAHTTEXT(overflow).replace("บาทถ้วน", "ล้าน") +
          BAHTTEXT(remains).replace("ศูนย์", "")
        );
      } else {
        len = num.length;
        for (i = 0; i < len; i = i + 1) {
          digit = parseInt(num.charAt(i), 10);
          if (digit > 0) {
            if (
              len > 2 &&
              i === len - 1 &&
              digit === 1 &&
              suffix !== "สตางค์"
            ) {
              text += "เอ็ด" + t[len - 1 - i];
            } else {
              text += n[digit] + t[len - 1 - i];
            }
          }
        }

        // grammar correction
        text = text.replace("หนึ่งสิบ", "สิบ");
        text = text.replace("สองสิบ", "ยี่สิบ");
        text = text.replace("สิบหนึ่ง", "สิบเอ็ด");

        return text + suffix;
      }
    }
  }
};

export const invoiceNumberFormat = (invoiceID) => {
  const prefix = "A";
  const newInvoiceID = pad(invoiceID, 3);
  return prefix + newInvoiceID;
};

export const billNumberFormat = (billID) => {
  const currentYear = dayjs().year();
  const newBillID = pad(billID, 4);
  return currentYear + newBillID;
};

const pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
};

export const formatArrayInvoiceID = (invoiceIDs: Array) => {
  let res = "";
  invoiceIDs.forEach((item, index) => {
    if (index === 0) {
      res += invoiceNumberFormat(item);
    } else {
      let temp = ", " + invoiceNumberFormat(item);
      res += temp;
    }
  });
  return res;
};
