import React, { useRef } from "react";
import { IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import { LogOut as LogOutIcon } from "react-feather";
import useAuth from "src/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleLogout = async () => {
    await logout();
    navigate(`/login`);
  };

  return (
    <>
      <Tooltip title="Logout">
        <IconButton onClick={handleLogout} ref={ref}>
          <SvgIcon fontSize="small">
            <LogOutIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Logout;
