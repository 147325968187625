import React, { Fragment, useState, useEffect } from "react";
import {
  makeStyles,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  TableHead,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import { numberWithCommas, BAHTTEXT } from "src/utils/utilities";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "900",
    },
  },
  additionalText: {
    textAlign: "left",
  },
  margin: {
    marginTop: "20px",
    marginBottom: "10px",
  },
}));

const CreateBillTable = ({
  search,
  items,
  additionalTextProps,
  setItems,
  setTotalAmount,
  setAdditionalTextprops,
}) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleEditMerchant = (params) => {
    navigate(`/app/merchant/edit/${params.id}`);
  };
  const [rows, setRows] = useState(items);
  const [freeItem, setFreeItem] = useState([]);
  const [total, setTotal] = useState();
  const [additionalText, setAdditionalText] = useState(additionalTextProps);

  const inputLabelProp = {
    shrink: true,
  };

  const calculateTotalPricePerItem = () => {
    let items = [];
    rows.map((row, index) => {
      let total =
        row.priceOrder *
        row.amount *
        (1 - row.discount1 / 100) *
        (1 - row.discount2 / 100);
      row.totalPrice = total;
      items.push(row);
    });
    setRows(items);
    setItems(items);
  };

  useEffect(() => {
    calculateTotalPricePerItem();
  }, []);

  useEffect(() => {
    setRows(items);
    setFreeItem(items.filter((item) => item.isFree));
    sumTotal();
  }, [items]);

  useEffect(() => {
    sumTotal();
  }, [rows]);

  const sumTotal = () => {
    let total = 0;
    rows.map((item) => (total += item.totalPrice));
    setTotal(total);
    setTotalAmount(total);
  };

  const onPriceChange = (value, index) => {
    let amount = parseFloat(rows[index].amount);
    let newTotal =
      value *
      amount *
      (1 - rows[index].discount1 / 100) *
      (1 - rows[index].discount2 / 100);
    rows[index].priceOrder = value;
    rows[index].totalPrice = newTotal;
    setRows([...rows]);
    setItems([...rows]);
  };

  const onFreeAmountChange = (value, index) => {
    // console.log("onFreeAmountChange");
    rows[index].freeAmount = value;
    setRows([...rows]);
    setItems([...rows]);
  };

  const onAmountChange = (value, index) => {
    let price = parseFloat(rows[index].priceOrder);
    let newTotal =
      price *
      value *
      (1 - rows[index].discount1 / 100) *
      (1 - rows[index].discount2 / 100);
    rows[index].amount = value;
    rows[index].totalPrice = newTotal;
    setRows([...rows]);
    setItems([...rows]);
  };

  const onDiscount1Change = (value, index) => {
    let price = parseFloat(rows[index].priceOrder);
    let amount = parseFloat(rows[index].amount);
    let newTotal =
      price * amount * (1 - value / 100) * (1 - rows[index].discount2 / 100);
    rows[index].discount1 = value;
    rows[index].totalPrice = newTotal;
    setRows([...rows]);
    setItems([...rows]);
  };

  const onDiscount2Change = (value, index) => {
    let price = parseFloat(rows[index].priceOrder);
    let amount = parseFloat(rows[index].amount);
    let newTotal =
      price * amount * (1 - rows[index].discount1 / 100) * (1 - value / 100);
    rows[index].discount2 = value;
    rows[index].totalPrice = newTotal;
    setRows([...rows]);
    setItems([...rows]);
  };

  const onAdditionalTextChange = (value) => {
    setAdditionalText(value);
    setAdditionalTextprops(value);
  };

  return (
    <Fragment>
      {/* <button type="button" onClick={()=>console.log(rows)}>TEST</button> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell></TableCell> */}
              <TableCell>ลำดับ</TableCell>
              <TableCell>รายละเอียด</TableCell>
              <TableCell align="right">จำนวน</TableCell>
              <TableCell align="right">หน่วย</TableCell>
              <TableCell align="right">ราคาขาย</TableCell>
              <TableCell align="right">ส่วนลด 1</TableCell>
              <TableCell align="right">ส่วนลด 2</TableCell>
              <TableCell align="right">จำนวนเงิน</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell>{row.merchandiseName}</TableCell>
                <TableCell align="right">
                  <TextField
                    label="จำนวน"
                    InputProps={{ inputProps: { min: 0, step: 0.5 } }}
                    variant="outlined"
                    InputLabelProps={inputLabelProp}
                    size="small"
                    type="number"
                    defaultValue={row.amount}
                    onChange={(e) => onAmountChange(e.target.value, index)}
                    style={{ width: "6rem" }}
                  />
                </TableCell>
                <TableCell align="right">{row.unit}</TableCell>
                <TableCell align="right">
                  <TextField
                    label="ราคาขาย"
                    InputProps={{ inputProps: { min: 0, step: 0.01 } }}
                    variant="outlined"
                    type="number"
                    InputLabelProps={inputLabelProp}
                    size="small"
                    onChange={(e) => onPriceChange(e.target.value, index)}
                    defaultValue={row.priceOrder}
                    style={{ width: "6rem" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    label="ส่วนลด 1"
                    variant="outlined"
                    InputLabelProps={inputLabelProp}
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 100, step: 0.01 },
                    }}
                    size="small"
                    onChange={(e) => onDiscount1Change(e.target.value, index)}
                    defaultValue={row.discount1}
                    style={{ width: "6rem" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    label="ส่วนลด 2"
                    InputProps={{
                      inputProps: { min: 0, max: 100, step: 0.01 },
                    }}
                    type="number"
                    variant="outlined"
                    onChange={(e) => onDiscount2Change(e.target.value, index)}
                    InputLabelProps={inputLabelProp}
                    size="small"
                    defaultValue={row.discount2}
                    style={{ width: "6rem" }}
                  />
                </TableCell>
                <TableCell align="right">
                  {numberWithCommas(row.totalPrice)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          {freeItem.length !== 0 && (
            <TableHead>
              <TableRow>
                <TableCell colSpan={6}>ของแถม</TableCell>
                <TableCell align="right" colSpan={1}></TableCell>
                <TableCell align="right" colSpan={1}></TableCell>
              </TableRow>
              {rows.map((item, index) => {
                if (!item.isFree) return <Fragment />;
                return (
                  <TableRow>
                    <TableCell colSpan={1}> {index + 1}</TableCell>
                    <TableCell colSpan={1}> {item.merchandiseName}</TableCell>
                    <TableCell align="right" colSpan={1}>
                      <TextField
                        label="จำนวน"
                        InputProps={{ inputProps: { min: 0 } }}
                        variant="outlined"
                        InputLabelProps={inputLabelProp}
                        size="small"
                        type="number"
                        defaultValue={item.freeAmount}
                        onChange={(e) =>
                          onFreeAmountChange(e.target.value, index)
                        }
                        style={{ width: "6rem" }}
                      />
                    </TableCell>
                    <TableCell align="right" colSpan={1}>
                      {item.unit}
                    </TableCell>
                    <TableCell align="right" colSpan={5}></TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          )}
          <TableHead>
            <TableRow>
              <TableCell colSpan={6}>
                {BAHTTEXT(numberWithCommas(parseFloat(total)))}
              </TableCell>
              <TableCell align="right" colSpan={1}>
                รวม
              </TableCell>
              <TableCell align="right" colSpan={1}>
                {numberWithCommas(parseFloat(total))}
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box className={classes.margin}>
        <Grid container spacing={2}>
          <Grid item xs={3} alignContent="center">
            <Typography
              className={classes.additionalText}
              variant={"subtitle2"}
              display="block"
              gutterBottom
            >
              หมายเหตุเพิ่มเติม / Note
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="หมายเหตุ"
              variant="outlined"
              size="small"
              onChange={(e) => onAdditionalTextChange(e.target.value)}
              defaultValue={additionalText}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default CreateBillTable;
