import * as React from "react";
import TextField from "@mui/material/TextField";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

export default function DateInput({
  label,
  setValue,
  error,
  helperText,
  defaultDate,
  views,
  inputFormat = "DD/MM/YYYY",
}) {
  // const [value, setValue] = React.useState(null);
  const [date, setDate] = React.useState(new Date());
  const handleChange = (newValue) => {
    setDate(newValue);
    setValue(newValue);
  };
  React.useEffect(() => {
    setDate(defaultDate);
  }, [defaultDate]);

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <DatePicker
        views={views}
        label={label}
        value={date}
        error={error}
        helperText={helperText}
        onChange={(newValue) => {
          handleChange(newValue);
        }}
        renderInput={(params) => <TextField fullWidth {...params} />}
        inputFormat={inputFormat}
        // disableFuture
      />
    </LocalizationProvider>
  );
}
