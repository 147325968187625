export const INVOICE_CREATE = "INVOICE_CREATE";
export const INVOICE_GET_LIST = "INVOICE_GET_LIST";
export const UPDATE_INVOICE_LIST = "UPDATE_INVOICE_LIST";
export const DELETE_INVOICE_BY_ID = "DELETE_INVOICE_BY_ID";
export const INVOICE_PRINT = "INVOICE_PRINT";

export const createInvoice = (data) => ({
  type: INVOICE_CREATE,
  payload: data,
});

export const getInvoiceByCondition = (data) => ({
  type: INVOICE_GET_LIST,
  payload: data,
});

export const updateInvoiceList = (data) => ({
  type: UPDATE_INVOICE_LIST,
  payload: data,
});

export const deleteInvoiceByID = (data) => ({
  type: DELETE_INVOICE_BY_ID,
  payload: data,
});

export const printInvoice = (data) => ({
  type: INVOICE_PRINT,
  payload: data,
});