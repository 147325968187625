import { apiCall, apiUpload } from "src/utils/axios";

export const apiGetCatalogList = () => {
  return apiCall("GET", "api/v1/merchandise", null, null);
};

export const apiCreateCatalog = (payload) => {
  return apiCall("POST", "api/v1/create-merchandise", payload, null);
};

export const apiGetCatalogByID = (payload) => {
  const url = `api/v1/merchandise/id/${payload}`;
  return apiCall("GET", url, null, null);
};

export const apiEditCatalogByID = (payload) => {
  const url = `api/v1/update-merchandise/${payload.id}`;
  return apiCall("POST", url, payload.data, null);
};

export const apiDeleteCatalogByID = (payload) => {
  const url = `api/v1/delete-merchandise/${payload}`;
  return apiCall("POST", url, null, null);
};

export const apiUploadImageCatalog = (payload) => {
  console.log(payload);
  const url = `api/v1/image/upload/${payload.catalogID}`;
  return apiUpload("POST", url, payload.data, null);
};
