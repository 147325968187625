import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function ComboBox({ label, options, setValue }) {
  const [data, setData] = React.useState();

  const handleChange = (newValue) => {
    setData(newValue);
    if (newValue !== null) {
      setValue(newValue);
    }
  };

  return (
    <Autocomplete
      disablePortal
      fullWidth
      id="combo-box-demo"
      options={options}
      value={data}
      onChange={(e, newValue) => {
        handleChange(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} fullWidth label={label} />
      )}
      getOptionLabel={(options) => options.name}
    />
  );
}
