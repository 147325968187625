export const BILL_SAVE = "BILL_SAVE";
export const BILL_REQUEST_NEW_BILL_ID = "BILL_REQUEST_NEW_BILL_ID";
export const BILL_GET_LIST = "BILL_GET_LIST";
export const BILL_GET_BY_ID = "BILL_GET_BY_ID";
export const BILL_GET_BY_MERCHANT_ID = "BILL_GET_BY_MERCHANT_ID";
export const BILL_UPDATE_LIST = "BILL_UPDATE_LIST";
export const BILL_UPDATE = "BILL_UPDATE";
export const DELETE_BILL_BY_ID = "DELETE_BILL_BY_ID";
export const UPDATE_BILL_REQUEST_NEW_BILL_ID =
  "UPDATE_BILL_REQUEST_NEW_BILL_ID";

export const saveBill = (
  data,
  shouldPrint,
  shouldAddCatalog,
  newBillID,
  history
) => ({
  type: BILL_SAVE,
  shouldPrint: shouldPrint,
  shouldAddCatalog: shouldAddCatalog,
  newBillID: newBillID,
  payload: data,
  history: history,
});

export const requestNewBillID = (data) => ({
  type: BILL_REQUEST_NEW_BILL_ID,
  payload: data,
});

export const updateRequestNewBillID = (data) => ({
  type: UPDATE_BILL_REQUEST_NEW_BILL_ID,
  payload: data,
});

export const getBillsWithMerchants = (data) => ({
  type: BILL_GET_LIST,
  payload: data,
});

export const updateBills = (data) => ({
  type: BILL_UPDATE_LIST,
  payload: data,
});

export const getBillByID = (data) => ({
  type: BILL_GET_BY_ID,
  payload: data,
});

export const updateBill = (data) => ({
  type: BILL_UPDATE,
  payload: data,
});

export const getBillByMerchantID = (data) => ({
  type: BILL_GET_BY_MERCHANT_ID,
  payload: data,
});

export const deleteBillByID = (data) => ({
  type: DELETE_BILL_BY_ID,
  payload: data,
});
