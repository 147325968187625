import { put, call, take } from "redux-saga/effects";
import {
  BILL_SAVE,
  BILL_REQUEST_NEW_BILL_ID,
  updateRequestNewBillID,
  BILL_GET_LIST,
  updateBills,
  BILL_GET_BY_ID,
  updateBill,
  BILL_GET_BY_MERCHANT_ID,
  DELETE_BILL_BY_ID,
} from "src/actions/BillAction";
import {
  apiSaveBill,
  apiRequestNewBillID,
  apiGetBillsWithMerchants,
  apiGetBillByID,
  apiGetBillByMerchantID,
  apiDeleteBillByID,
} from "src/api/BillApi";
import { handleStatusError, handleStatusSuccess } from ".";
import { hideSpinner, showSpinner } from "src/components";
import { printPDF } from "src/utils/pdf";

export function* workerSaveBill(action) {
  try {
    yield call(showSpinner);
    const navigate = action.history;
    if (action.payload.billItems.length === 0) {
      let err = new Error("โปรดใส่รายการสินค้า");
      throw err;
    }
    let value = action.payload.billItems.reduce(function (
      previousValue,
      item,
      index
    ) {
      let newItem = {
        merchandiseID: item.merchandiseID.toString(),
        amount: parseFloat(item.amount),
        discount1: parseFloat(item.discount1),
        discount2: parseFloat(item.discount2),
        priceOrder: parseFloat(item.priceOrder),
        totalPrice: parseFloat(item.totalPrice),
        isFree: true,
        freeAmount: parseFloat(item.freeAmount),
        transactionID: item.orderTxnID,
        unit: item.unit,
      };
      return previousValue.concat(newItem);
    },
    []);
    const newRequest = { ...action.payload, billItems: value };
    const { data } = yield call(apiSaveBill, newRequest);
    if (data.message) {
      if (action.shouldPrint) {
        const bills = yield call(apiGetBillsWithMerchants, {
          billID: action.newBillID,
        });
        navigate(`/app/edit-bill/${action.newBillID}`);
        yield call(printPDF, bills.data.message[0]);
        window.location.reload(true);
        // yield call(window.location.reload(true));
      } else {
        if (action.shouldAddCatalog) {
          yield call(handleStatusSuccess, "Sucess Save Bill");
          navigate("/app/catalog/create");
        } else {
          yield call(handleStatusSuccess, "Sucess Save Bill");
          navigate(`/app/edit-bill/${action.payload.billID}`);
          window.location.reload(true);
        }
      }
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherSaveBill() {
  while (true) {
    const action = yield take(BILL_SAVE);
    yield call(workerSaveBill, action);
  }
}

export function* workerRequestNewBillID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiRequestNewBillID);
    if (data.message) {
      yield put(updateRequestNewBillID(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherRequestNewBillID() {
  while (true) {
    const action = yield take(BILL_REQUEST_NEW_BILL_ID);
    yield call(workerRequestNewBillID, action);
  }
}

export function* workerGetBillsWithMerchants(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetBillsWithMerchants, action.payload);
    if (data.message) {
      yield put(updateBills(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetBillsWithMerchants() {
  while (true) {
    const action = yield take(BILL_GET_LIST);
    yield call(workerGetBillsWithMerchants, action);
  }
}

export function* workerGetBillByID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetBillByID, action.payload);
    if (data.message) {
      yield put(updateBill(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetBillsByID() {
  while (true) {
    const action = yield take(BILL_GET_BY_ID);
    yield call(workerGetBillByID, action);
  }
}

export function* workerGetBillByMerchantID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiGetBillByMerchantID, action.payload);
    if (data.message) {
      yield put(updateBills(data.message));
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherGetBillsByMerchantID() {
  while (true) {
    const action = yield take(BILL_GET_BY_MERCHANT_ID);
    yield call(workerGetBillByMerchantID, action);
  }
}

export function* workerDeleteBillByID(action) {
  try {
    yield call(showSpinner);
    const { data } = yield call(apiDeleteBillByID, action.payload);
    if (data.message) {
      yield call(handleStatusSuccess, "Delete Bill Success");
    }
  } catch (error) {
    yield call(handleStatusError, error);
  } finally {
    yield call(hideSpinner);
  }
}

export function* watcherDeleteBillsByID() {
  while (true) {
    const action = yield take(DELETE_BILL_BY_ID);
    yield call(workerDeleteBillByID, action);
  }
}
