export const CATALOG_GET_LIST = "CATALOG_GET_LIST";
export const CATALOG_GET_BY_ID = "CATALOG_GET_BY_ID";
export const CATALOG_UPDATE_LIST = "CATALOG_UPDATE_LIST";
export const CATALOG_UPDATE = "CATALOG_UPDATE";
export const CATALOG_CREATE = "CATALOG_CREATE";
export const CATALOG_EDIT = "CATALOG_EDIT";
export const CATALOG_DELETE = "CATALOG_DELETE";
export const CATALOG_UPLOAD = "CATALOG_UPLOAD";

export const getCatalogList = (data) => ({
  type: CATALOG_GET_LIST,
  payload: data,
});

export const updateCatalogList = (data) => ({
  type: CATALOG_UPDATE_LIST,
  payload: data,
});

export const createCatalog = (data, navigate) => ({
  type: CATALOG_CREATE,
  payload: data,
  navigate: navigate,
});

export const getCatalogByID = (data) => ({
  type: CATALOG_GET_BY_ID,
  payload: data,
});

export const updateCatalog = (data) => ({
  type: CATALOG_UPDATE,
  payload: data,
});

export const editCatalog = (data, navigate) => ({
  type: CATALOG_EDIT,
  payload: data,
  navigate: navigate,
});

export const deleteCatalog = (data) => ({
  type: CATALOG_DELETE,
  payload: data,
});

export const uploadImageCatalog = (data) => ({
  type: CATALOG_UPLOAD,
  payload: data,
});
