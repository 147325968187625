import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  numberWithCommas,
  numberWithCommasInterger,
  formatDate,
  BAHTTEXT,
  billNumberFormat,
} from "src/utils/utilities";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

function getBase64ImageFromURL(url) {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.setAttribute("crossOrigin", "anonymous");

    img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      // canvas.width = 200;
      // canvas.height = 200;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = url;
  });
}

export async function printPDF(data) {
  console.log("=============== printPDF ===============");
  // console.log(data);
  var docDefinition = {
    pageMargins: [20, 20, 20, 20],
    content: [
      {
        alignment: "justify",
        // margin header
        margin: [0, 0, 0, 10],
        columns: [
          {
            image: await getBase64ImageFromURL(
              "https://pclstore.s3.ap-southeast-1.amazonaws.com/logo/pig.png"
            ),
            width: 120,
          },
          {
            text: "",
            width: 65,
          },
          {
            image: await getBase64ImageFromURL(
              "https://pclstore.s3.ap-southeast-1.amazonaws.com/logo/pcl_logo.png"
            ),
            width: 70,
          },
          {
            text: "",
          },
          {
            style: "tableExample",
            margin: [0, 0, 0, 20],
            table: {
              widths: ["*"],
              heights: [20],
              body: [
                [
                  {
                    text: "หมายเลขบิล",
                    alignment: "center",
                    fontSize: 16,
                    bold: true,
                    fillColor: "#B4E5FF",
                  },
                ],
                [
                  {
                    text: `${billNumberFormat(data.billID)}`,
                    fontSize: 14,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
        ],
      },
      {
        alignment: "justify",
        margin: [0, 0, 0, 10],
        columns: [
          {
            style: "tableExample",
            table: {
              widths: [50, "*"],
              heights: [20, 20],
              body: [
                [
                  {
                    text: "ชื่อลูกค้า",
                    fontSize: 18,
                    bold: true,
                    alignment: "center",
                  },
                  {
                    text: `${data.merchantName}`,
                    fontSize: 18,
                    bold: true,
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: "ที่อยู่",
                    bold: true,
                    fontSize: 18,
                    alignment: "center",
                  },
                  {
                    text: `${data.address}`,
                    bold: true,
                    fontSize: 18,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
          {
            style: "tableExample",
            table: {
              widths: [70, "*"],
              heights: [20, 20],
              body: [
                [
                  {
                    text: "วันที่",
                    fontSize: 18,
                    bold: true,
                    alignment: "center",
                  },
                  {
                    text: `${formatDate(data.bill_created_date)}`,
                    fontSize: 18,
                    bold: true,
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: "การชำระเงิน",
                    fontSize: 18,
                    bold: true,
                    alignment: "center",
                  },
                  {
                    text: `${data.paymentMethod}`,
                    fontSize: 18,
                    bold: true,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
        ],
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [12, "*", 40, 40, 50, 22, 22, 70],
          heights: [
            20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
            20, 20,
          ],
          body: [
            [
              {
                text: "รายละเอียด",
                bold: true,
                colSpan: 2,
                alignment: "center",
                fontSize: 14,
                fillColor: "#FFE4A1",
              },
              {},
              {
                text: "จำนวน",
                bold: true,
                fontSize: 14,
                alignment: "center",
                fillColor: "#FFE4A1",
              },
              {
                text: "หน่วย",
                bold: true,
                fontSize: 14,
                fillColor: "#FFE4A1",
                alignment: "center",
              },
              {
                text: "ราคาขาย",
                bold: true,
                fontSize: 14,
                fillColor: "#FFE4A1",
                alignment: "center",
              },
              {
                text: "ส่วนลด",
                bold: true,
                colSpan: 2,
                fontSize: 14,
                fillColor: "#FFE4A1",
                alignment: "center",
              },
              {},
              {
                text: "จำนวนเงิน",
                fillColor: "#FFE4A1",
                fontSize: 14,
                alignment: "center",
                bold: true,
              },
            ],
            // item
            ...renderItem(data.orderTransactions, data.additionalText),
            [
              {
                colSpan: 2,
                text: `${BAHTTEXT(data.billTotalAmount)}`,
                fontSize: 14,
                bold: true,
              },
              "",
              {
                border: [true, true, false, false],
                text: "",
              },
              {
                border: [false, true, false, false],
                text: "",
              },
              {
                colSpan: 3,
                text: "รวมเงิน",
                bold: true,
                fontSize: 16,
                alignment: "center",
              },
              "",
              "",
              {
                text: `${numberWithCommas(data.billTotalAmount)}`,
                fontSize: 16,
                bold: true,
                alignment: "center",
              },
            ],
          ],
        },
        margin: [0, 0, 0, 10],
      },
      {
        style: "tableExample",
        table: {
          widths: [10, "*", 30, 30, 40, 20, 20, 80],
          heights: [20],
          body: [
            [
              {
                border: [false, false, false, false],
                text: "",
                bold: true,
                colSpan: 2,
              },
              {},
              {
                border: [false, false, false, false],
                text: "",
                bold: true,
              },
              {
                border: [false, false, false, false],
                text: "",
                bold: true,
              },

              {
                text: "ยอดเงินสุทธิ",
                bold: true,
                colSpan: 3,
                fontSize: 18,
              },
              {},
              {},
              {
                text: `${numberWithCommas(data.billTotalAmount)}`,
                fontSize: 18,
                bold: true,
                alignment: "center",
              },
            ],
          ],
        },
      },
      {
        style: "tableExample",
        table: {
          heights: [5, 10, 5],
          body: [
            [
              {
                text: "",
                fontSize: 14,
                alignment: "center",
                border: [true, true, true, false],
              },
            ],
            [
              {
                border: [true, false, true, false],
                text: "ผู้รับสินค้า ................................................................................................................",
                fontSize: 14,
                alignment: "center",
              },
            ],
            [
              {
                text: "",
                fontSize: 14,
                alignment: "center",
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
    ],
    defaultStyle: {
      font: "THSarabunNew",
      columnGap: 30,
    },
    style: {
      subheader: {
        fontSize: 20,
        bold: true,
      },
      addText: {
        bold: true,
      },
    },
  };
  pdfMake.createPdf(docDefinition).open();
  // pdfMake.createPdf(docDefinition).print();
}

const renderItem = (items, additionalText) => {
  const maxRow = 17;
  let res = [];
  items.map((item, index) => {
    let record = [
      {
        text: `${index + 1}`,
        alignment: "center",
        fontSize: 14,
        border: [true, false, false, false],
      },
      {
        text: `${item.merchandiseName}`,
        fontSize: 15,
        border: [true, false, false, false],
      },
      {
        text: `${numberWithCommasInterger(item.amount)}`,
        alignment: "center",
        fontSize: 15,
        border: [true, false, false, false],
      },
      {
        text: `${item.unit}`,
        alignment: "center",
        fontSize: 15,
        border: [true, false, false, false],
      },
      {
        text: `${numberWithCommas(item.priceOrder)}`,
        alignment: "center",
        fontSize: 15,
        border: [true, false, false, false],
      },
      {
        text: `${item.discount1 === 0 ? "" : `${item.discount1}%`}`,
        alignment: "center",
        fontSize: 15,
        border: [true, false, false, false],
      },
      {
        text: `${item.discount2 === 0 ? "" : `${item.discount2}%`}`,
        alignment: "center",
        fontSize: 15,
        border: [false, false, false, false],
      },
      {
        text: `${numberWithCommas(item.totalPrice)}`,
        alignment: "center",
        fontSize: 15,
        border: [true, false, true, false],
      },
    ];
    res.push(record);

    // section free item
    if (item.isFree) {
      let freeRecord = [
        {
          text: ``,
          alignment: "center",
          fontSize: 15,
          border: [true, false, false, false],
        },
        {
          text: `แถม`,
          fontSize: 15,
          border: [true, false, false, false],
        },
        {
          text: `${item.freeAmount}`,
          alignment: "center",
          fontSize: 15,
          border: [true, false, false, false],
        },
        {
          text: `${item.unit}`,
          alignment: "center",
          fontSize: 15,
          border: [true, false, false, false],
        },
        {
          text: `0.0`,
          alignment: "center",
          fontSize: 16,
          border: [true, false, false, false],
        },
        {
          text: ``,
          alignment: "center",
          fontSize: 15,
          border: [true, false, false, false],
        },
        {
          text: ``,
          alignment: "center",
          fontSize: 15,
          border: [false, false, false, false],
        },
        {
          text: `0.00`,
          alignment: "center",
          fontSize: 15,
          border: [true, false, true, false],
        },
      ];
      // free.push(freeRecord);
      res.push(freeRecord);
    }
  });

  const currentRow = res.length;
  const halfLine = Math.floor((maxRow - currentRow) / 2);

  let additionalText1 = "";
  let additionalText2 = "";
  let additionalText3 = "";

  if (additionalText.length > 40) {
    additionalText1 = additionalText.slice(0, 40);
    additionalText2 = additionalText.slice(41, 80);
    additionalText3 = additionalText.slice(81, additionalText.length);
  } else {
    additionalText1 = additionalText;
  }

  for (let i = 0; i <= maxRow - currentRow; i++) {
    additionalText = "";
    if (i == halfLine) {
      additionalText = additionalText1;
    }

    if (i == halfLine + 1) {
      additionalText = additionalText2;
    }

    if (i == halfLine + 2) {
      additionalText = additionalText3;
    }

    res.push([
      {
        text: ``,
        border: [true, false, false, false],
      },
      {
        text: `${additionalText}`,
        fontSize: 16,
        border: [true, false, false, false],
        bold: true,
      },
      {
        text: ``,
        border: [true, false, false, false],
      },
      {
        text: ``,
        border: [true, false, false, false],
      },
      {
        text: ``,
        border: [true, false, false, false],
      },
      {
        text: ``,
        border: [true, false, false, false],
      },
      {
        text: ``,
        border: [false, false, false, false],
      },
      {
        text: ``,
        border: [true, false, true, false],
      },
    ]);
  }
  return res;
};

export async function invoicePDF(data) {
  console.log("=============== invoicePDF ===============");
  // console.log(data);
  var docDefinition = {
    content: [
      {
        margin: [0, 0, 0, 10],
        columns: [
          [
            {
              text: "ใบวางบิล/ใบแจ้งหนี้",
              style: "header",
              fontSize: 30,
              bold: true,
            },
            {
              text: "Invoice",
              style: "header",
              fontSize: 30,
              bold: true,
            },
          ],
          {},
          {
            image: await getBase64ImageFromURL(
              "https://pclstore.s3.ap-southeast-1.amazonaws.com/logo/pcl.png"
            ),
            width: 140,
          },
        ],
      },
      {
        alignment: "justify",
        margin: [0, 0, 0, 10],
        columns: [
          {
            style: "tableExample",
            table: {
              widths: [50, 140],
              heights: [20, 20],
              body: [
                [
                  {
                    text: "ชื่อลูกค้า",
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                  },
                  {
                    text: `${data[0].merchantName}`,
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: "ที่อยู่",
                    bold: true,
                    fontSize: 16,
                    alignment: "center",
                  },
                  {
                    text: `${data[0].address}`,
                    bold: true,
                    fontSize: 16,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
          {
            style: "tableExample",
            table: {
              widths: [80, "*"],
              heights: [20, 20],
              body: [
                [
                  {
                    text: "",
                    border: [false, false, false, false],
                  },
                  {
                    text: "วันที่วางบิล",
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                    fillColor: "#B4E5FF",
                  },
                ],
                [
                  {
                    text: ``,
                    border: [false, false, false, false],
                  },
                  {
                    text: `${formatDate(data[0].ExecuteAt)}`,
                    fontSize: 16,
                    bold: true,
                    alignment: "center",
                  },
                ],
              ],
            },
          },
        ],
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: [120, 120, 120, 120],
          heights: [
            20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20,
            20, 20,
          ],
          body: [
            [
              {
                text: "วันที่\nDate",
                bold: true,
                alignment: "center",
                fillColor: "#FFE4A1",
                fontSize: 16,
              },
              {
                text: "เลขที่บิล\nInvoice",
                bold: true,
                alignment: "center",
                fillColor: "#FFE4A1",
                fontSize: 16,
              },
              {
                text: "หมายเหตุ\nRemark",
                alignment: "center",
                bold: true,
                fillColor: "#FFE4A1",
                fontSize: 16,
              },
              {
                text: "จำนวนเงิน\nAmount",
                alignment: "center",
                bold: true,
                fillColor: "#FFE4A1",
                fontSize: 16,
              },
            ],
            // item
            ...renderInvoiceItem(data),
            [
              {
                text: "",
                border: [false, true, false, false],
              },
              {
                border: [false, true, false, false],
                text: "",
              },
              {
                text: "รวมเงิน",
                bold: true,
                fontSize: 16,
                alignment: "center",
              },
              {
                text: `${numberWithCommas(data[0].TotalAmount)}`,
                fontSize: 18,
                bold: true,
                alignment: "center",
              },
            ],
            [
              {
                text: "",
                border: [false, false, false, false],
              },
              {
                border: [false, false, false, false],
                text: "",
              },
              {
                text: `${BAHTTEXT(data[0].TotalAmount)}`,
                fontSize: 13,
                bold: true,
                alignment: "center",
                colSpan: 2,
              },
              {},
            ],
          ],
        },
        margin: [0, 0, 0, 20],
      },
      {
        alignment: "justify",
        margin: [0, 0, 0, 10],
        columns: [
          {
            table: {
              widths: ["*"],
              heights: [5, 10, 5],
              body: [
                [
                  {
                    text: "",
                    fontSize: 14,
                    alignment: "center",
                    border: [true, true, true, false],
                  },
                ],
                [
                  {
                    border: [true, false, true, false],
                    text: "ผู้รับวางบิล ...........................................................................",
                    fontSize: 14,
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: "",
                    fontSize: 14,
                    alignment: "center",
                    border: [true, false, true, true],
                  },
                ],
              ],
            },
          },
          {
            style: "tableExample",
            table: {
              widths: ["*"],
              heights: [5, 10, 5],
              body: [
                [
                  {
                    text: "",
                    fontSize: 14,
                    alignment: "center",
                    border: [true, true, true, false],
                  },
                ],
                [
                  {
                    border: [true, false, true, false],
                    text: "วันนัดจ่าย ...........................................................................",
                    fontSize: 14,
                    alignment: "center",
                  },
                ],
                [
                  {
                    text: "",
                    fontSize: 14,
                    alignment: "center",
                    border: [true, false, true, true],
                  },
                ],
              ],
            },
          },
        ],
      },
    ],
    defaultStyle: {
      font: "THSarabunNew",
      columnGap: 20,
    },
  };
  pdfMake.createPdf(docDefinition).open();
}

const renderInvoiceItem = (items) => {
  const maxRow = 15;
  // console.log(items);
  let res = [];
  items.map((item, index) => {
    let record = [
      {
        text: `${formatDate(item.bill_created_date)}`,
        alignment: "center",
        fontSize: 16,
        border: [true, false, true, false],
      },
      {
        text: `${billNumberFormat(item.billID)}`,
        alignment: "center",
        fontSize: 16,
        border: [false, false, true, false],
      },
      {
        text: `${item.paymentMethod}`,
        alignment: "center",
        fontSize: 16,
        border: [false, false, true, false],
      },
      {
        text: `${numberWithCommas(item.billTotalAmount)}`,
        alignment: "center",
        fontSize: 16,
        border: [false, false, true, false],
      },
    ];
    res.push(record);
  });
  for (let i = 0; i < maxRow - items.length; i++) {
    res.push([
      {
        text: ``,
        border: [true, false, true, false],
      },
      {
        text: ``,
        border: [false, false, true, false],
      },
      {
        text: ``,
        border: [false, false, true, false],
      },
      {
        text: ``,
        border: [false, false, true, false],
      },
    ]);
  }
  return res;
};
