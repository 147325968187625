const Colors = {
  COLOR_MAIN: "#275F7E",
  // COLOR_MAIN: "#A3262A",
  TEXT_WHITE: "#FFFFFF",
  TEXT_BLACK: "#000000",
  TEXT_GRAY: "#8D8D8D",
  BUTTON_HOVER: "#4C7E53",
  ORANGE: "#F3890C",
  RED: "#F60003",
  GREEN: "#355645",
  DIVIDER_GRAY: "#B9B9B9",
};

export default Colors;
