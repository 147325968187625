import React, { useState, useEffect, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles, Box } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import {
  IconButton,
  ModalCustom,
  MainButton,
  UnderlineButton,
  ScrollDialog,
} from "src/components";
import {
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  numberWithCommas,
  numberPercent,
} from "src/utils/utilities";
import { EditOutlined as EditOutlinedIcon } from "@material-ui/icons";
import { useDispatch, useSelector } from "src/store";
import { getTopProductDetail } from "src/actions/DashboardAction";
import { useSearchParams } from "react-router-dom";
import { queryParamsToMap } from "src/utils/queryParamsToMap";
import { DashboardSelector } from "src/reducers/DashboardReducer";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "900",
    },
  },
  marginHorizontal: {
    marginRight: "15px",
    marginLeft: "15px",
  },
}));

const TopProductTable = ({ items }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [rows, setRows] = useState(items);
  const [selectIndex, setSelectIndex] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClickOpen = (id) => {
    console.log(queryParamsToMap(searchParams.toString()));
    let param = queryParamsToMap(searchParams.toString());
    param.merchandiseId = id;
    console.log(param);

    dispatch(getTopProductDetail(param));
    setIsOpen(true);
  };
  const dispatch = useDispatch();

  const { TopProductDetail } = useSelector(DashboardSelector);

  const columns = [
    {
      field: "merchandiseName",
      headerName: "ชื่อสินค้า ขายดีที่สุด 100 อันดับ",
      width: 500,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total",
      headerName: "ยอดขายทั้งหมด",
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        return numberWithCommas(params.value);
      },
      headerClassName: "super-app-theme--header",
    },
    {
      field: "percentage",
      headerName: "เปอร์เซ็น (%)",
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        return numberPercent(params.value);
      },
      headerClassName: "super-app-theme--header",
    },
    {
      field: "numberOfBill",
      headerName: "จำนวนบิล",
      width: 250,
      renderCell: (params) => {
        return (
          <Stack direction="row" alignItems="center" spacing={0}>
            {numberWithCommas(params.value)}
            <UnderlineButton
              title={"ดูรายละเอียด"}
              type={"button"}
              className={classes.marginHorizontal}
              onClick={() => handleClickOpen(params.id)}
            />
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    setRows(items);
  }, [items]);

  return (
    <Box
      style={{ height: 670, width: "100%" }}
      sx={{
        "& .super-app-theme--header": {},
      }}
    >
      <DataGrid
        className={classes.table}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(data) => data.merchandiseId}
      />
      <ScrollDialog
        isOpen={isOpen}
        setIsOpenCallback={setIsOpen}
        data={TopProductDetail}
      />
    </Box>
  );
};

export default TopProductTable;
