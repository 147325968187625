import React from "react";

import PropTypes from "prop-types";
import clsx from "clsx";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { THEMES } from "src/constants";
import PCL_LogoNav from "src/assets/images/pcl_logo_nav.png";
import Augpao from "src/assets/images/chinaday/augpao.png";
import Tag from "src/assets/images/chinaday/tag.png";

import Logout from "./Logout";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: "1",
          backgroundColor: theme.palette.background.default,
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: 64,
  },
  icon: {
    color: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.text.primary,
    fontWeight: "900",
    textAlign: "start",
  },
  image: {
    width: 120,
    height: 66,
  },
  imageSmaill: {
    width: 50,
    marginLeft: 10,
    marginRight: 10,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <SvgIcon fontSize="small">
              <MenuIcon className={classes.icon} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <Typography variant="h3" color="black" className={classes.title}>
            <img className={classes.image} alt="Logo" src={PCL_LogoNav} />
          </Typography>
        </Hidden>
        <Box display={"flex"} ml={4}>
          {/* <img className={classes.imageSmaill} alt="Logo" src={Augpao} />
          <img className={classes.imageSmaill} alt="Logo" src={Tag} /> */}
        </Box>
        <Box display={"flex"} justifyContent="flex-end" ml={2} flexGrow={1}>
          <Logout />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;
