import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Barchart } from "src/components";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    boxShadow:
      "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
    textAlign: "start",
  },
}));

const MonthSaleChart = ({ title, dataChart }) => {
  const classes = useStyles();
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    let tempLabels = [];
    let tempData = [];
    dataChart.map((item) => {
      tempLabels.push(item.monthName);
      tempData.push(item.total);
    });
    setLabels(tempLabels);
    setData(tempData);
  }, [dataChart]);

  return (
    <Card sx={{ minWidth: 275 }} className={classes.root}>
      <CardContent>
        <Typography sx={{ fontSize: 24 }} gutterBottom>
          {title}
        </Typography>
        <Barchart dataSet={data} labels={labels} label={"ยอดเงิน"} />
      </CardContent>
    </Card>
  );
};

export default MonthSaleChart;
