import React, { Fragment, useState, useEffect } from "react";
import {
  Typography,
  makeStyles,
  Box,
  TextField,
  Modal,
} from "@material-ui/core";
import { MainButton, GrayButton } from "src/components";

import { DataGrid } from "@mui/x-data-grid";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    backgroundColor: "#FFFFFF",
    border: "2px solid #FFF",
    boxShadow: 24,
    padding: 30,
    borderRadius: "20px",
    outline: "none",
  },
  image: {
    width: 120,
    height: 120,
  },
  button: {
    minWidth: "120px",
    margin: 10,
    "&:hover": {
      minWidth: "120px",
    },
  },
}));

const tempDate = [
  {
    id: 1,
    personalID: "23232232",
    companyName: "TTTT",
    branch: "PPPP",
    address: "Earth",
    responsibilityName: "GGG",
  },
  {
    id: 2,
    personalID: "23232232",
    companyName: "TTTT",
    branch: "PPPP",
    address: "Earth",
    responsibilityName: "GGG",
  },
];
const LinkModalCustom = ({ isOpen, setIsModalOpen, title }) => {
  const classes = useStyles();
  const [rows, setRows] = useState(tempDate);

  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const columns = [
    { field: "id", headerName: "ลำดับ", width: 70 },
    { field: "personalID", headerName: "เลขประจำตัวผู้เสียภาษี", width: 180 },
    { field: "companyName", headerName: "ชื่อบริษัท", width: 150 },
    {
      field: "branch",
      headerName: "สาขา",
      width: 150,
    },
    {
      field: "address",
      headerName: "ที่อยู่",
      sortable: false,
      width: 160,
    },
    { field: "responsibilityName", headerName: "ผู้รับผิดชอบ", width: 150 },
    {
      field: "action",
      headerName: "",
      width: 150,
      renderCell: (params) => {
        return <GrayButton title={"เลือก"} />;
      },
    },
  ];

  const mapTitle = (title) => {
    return title !== "company" ? `ค้นหาบริษัท` : `ค้นหาผู้สนใจ (Lead)`;
  };

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.root}>
          <Box display={"flex"} justifyContent="space-between">
            <Typography
              id="modal-modal-title"
              variant="h4"
              style={{ paddingTop: "4px", paddingBottom: "15px" }}
            >
              {mapTitle(title)}
            </Typography>
            <MainButton
              title={"ปิด"}
              className={classes.button}
              onClick={() => handleClose()}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ paddingRight: "1rem" }}
            >
              ค้นหา
            </Typography>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="พิมพ์ค้นหาบริษัท"
              variant="outlined"
              size="small"
              style={{ minWidth: "25rem" }}
            />
            <MainButton
              title={"ค้นหา"}
              className={classes.button}
              onClick={handleClose}
            />
          </Box>
          <div style={{ height: 250, width: "100%" }}>
            <DataGrid
              className={classes.table}
              rows={rows}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default LinkModalCustom;
