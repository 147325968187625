import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, makeStyles } from "@material-ui/core";
import { ModalCustom } from "src/components";
import { useNavigate } from "react-router-dom";
import {
  formatDate,
  numberWithCommas,
  invoiceNumberFormat,
  billNumberFormat,
  formatArrayInvoiceID,
} from "src/utils/utilities";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "900",
    },
  },
  positive: {
    color: "#1E8449",
    fontWeight: "900",
    textAlign: "start",
  },
  negative: {
    color: "#E74C3C",
    fontWeight: "900",
    textAlign: "start",
  },
}));

const InvoiceTable = ({ search, items, setSelectedBills }) => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const handleEditCatalog = (params) => {
    navigate(`/app/catalog/edit/${params.id}`);
  };
  const [rows, setRows] = useState(items);
  const [selectionModel, setSelectionModel] = React.useState([]);

  useEffect(() => {
    setRows(items);
  }, [items]);

  const columns = [
    {
      field: "billID",
      headerName: "หมายเลขบิล",
      width: 300,
      valueFormatter: (params: GridValueFormatterParams) => {
        return billNumberFormat(params.value);
      },
    },
    {
      field: "billTotalAmount",
      headerName: "ราคา",
      width: 150,
      valueFormatter: (params: GridValueFormatterParams) => {
        return numberWithCommas(params.value);
      },
    },
    {
      field: "paymentMethod",
      headerName: "การชำระเงิน",
      width: 150,
    },
    {
      field: "bill_created_date",
      headerName: "วันที่สร้างบิล",
      sortable: false,
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDate(params.value);
      },
    },
    {
      field: "bill_updated_date",
      headerName: "วันที่อัพเดทบิล",
      sortable: false,
      width: 200,
      valueFormatter: (params: GridValueFormatterParams) => {
        return formatDate(params.value);
      },
    },
    {
      field: "isInvoiceDeleted",
      headerName: "Status",
      width: 200,
      renderCell: (params) => {
        // console.log(params.row);
        // console.log(params.row.invoiceID);
        let c1 = params.row.invoiceIDs[0] === "";
        let isDeleted = params.row.isInvoiceDeleted;
        let isActive = c1 || isDeleted;
        return isActive ? (
          <Typography className={classes.negative} variant={"h5"}>
            ยังไม่เปิดใบวางบิล
          </Typography>
        ) : (
          <Typography className={classes.positive} variant={"h5"}>
            เปิดใบวางบิลแล้ว
          </Typography>
        );
      },
    },
    {
      field: "invoiceIDs",
      headerName: "หมายเลขใบวางบิล",
      width: 200,
      renderCell: (params) => {
        // console.log(params);
        // console.log(params.row);
        // console.log(params.row.invoiceID);
        let c1 = params.row.invoiceIDs[0] === "";
        return c1 ? (
          <Typography className={classes.negative} variant={"h5"}>
            {"-"}
          </Typography>
        ) : (
          <Typography className={classes.positive} variant={"h5"}>
            {formatArrayInvoiceID(params.row.invoiceIDs)}
          </Typography>
        );
      },
    },
  ];

  return (
    <div style={{ height: 470, width: "100%" }}>
      <DataGrid
        className={classes.table}
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        checkboxSelection
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
          setSelectedBills(newSelectionModel);
        }}
        selectionModel={selectionModel}
        getRowId={(data) => data.billID}
      />
      <ModalCustom isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default InvoiceTable;
