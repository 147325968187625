import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import { ButtonColor } from "../../components";

const getMainButtonClassStyle = (classStyle, color) => {
  switch (color) {
    case ButtonColor.ORAGNGE:
      return classStyle?.orange;
    case ButtonColor.RED:
      return classStyle?.red;
    case ButtonColor.GREEN:
      return classStyle?.green;
    default:
      return "";
  }
};

const IconButtonCustom = ({
  className,
  onClick,
  href,
  type,
  isDisable,
  color,
  startIcon,
  id,
  name,
}) => {
  const classes = useStyles();
  const classStyle = getMainButtonClassStyle(classes, color);
  const buttonComponent = () => {
    if (!!href && href.length) return RouterLink;

    switch (type) {
      case "submit":
      case "button":
      default:
        return "button";
    }
  };

  const dynamicProps = () => {
    const props = {};
    if (!!href && href.length) {
      props.to = href;
    }

    return props;
  };

  return (
    <IconButton
      className={clsx(classes.root, clsx(classStyle, className))}
      onClick={onClick}
      style={{ textTransform: "none" }}
      component={buttonComponent()}
      type={type}
      {...dynamicProps()}
      disabled={isDisable}
      id={id}
      name={name}
    >
      {startIcon}
    </IconButton>
  );
};

export default IconButtonCustom;

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    padding: 6,
  },
  red: {
    color: theme.palette.btn.red,
  },
  orange: {
    color: theme.palette.btn.orange,
  },
  green: {
    color: theme.palette.btn.green,
  },
}));
