export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const AuthorizeApi = `api/admin/`;
export const PatchLink = `api/download/`;
export const MediaLink = `api/download/`;
export const SystemLogFileLink = `api/admin/download/systemlog`;

const configs = {
  local: {
    API_ENDPOINT: "http://localhost:8002/",
  },
  prod: {
    API_ENDPOINT: "https://pclstore.bosskungdev.com/",
  },
};

export default () => {
  console.log(process.env.NODE_ENV);
  if (process.env.NODE_ENV !== "production") return configs["local"];
  else return configs["prod"];
};

export const VERSION = "1.2.10";
