/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import {
  DescriptionOutlined as DescriptionOutlinedIcon,
  StoreOutlined as StoreOutlinedIcon,
  SearchOutlined as SearchOutlinedIcon,
  BarChartOutlined as BarChartOutlinedIcon,
  AddShoppingCartOutlined as AddShoppingCartOutlinedIcon,
  AssignmentReturnOutlined as AssignmentReturnOutlinedIcon,
  ReceiptOutlined as ReceiptOutlinedIcon,
  BuildOutlined as BuildOutlinedIcon,
  TrendingUp as TrendingUpIcon
} from "@material-ui/icons";
import { Logo } from "src/components";
import useAuth from "src/hooks/useAuth";
import NavItem from "./NavItem";

function renderNavItems({ items, pathname, depth = 0, tier }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth, tier }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, tier }) {
  if (item.admin_guard && tier?.toUpperCase() !== "ADMIN") {
    return acc;
  }
  const key = item.title + depth;
  if (item.items) {
    // const open = matchPath(pathname, {
    //   path: item.href,
    //   exact: false,
    // });
    let open = false;

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        notification={item.notification}
        onClick={item.onClick}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          tier: tier,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        notification={item.notification}
        onClick={item.onClick}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    backgroundColor: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 258,
    top: 64,
    height: "calc(100% - 64px)",
    backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [sections, setSection] = useState([]);

  useEffect(() => {
    setSection([
      {
        items: [
          {
            title: "สร้างบิล",
            icon: DescriptionOutlinedIcon,
            href: "/app/create-bill",
          },
          {
            title: "สร้างใบวางบิล",
            icon: ReceiptOutlinedIcon,
            href: "/app/create-invoice",
          },
          {
            title: "Catalog",
            icon: BuildOutlinedIcon,
            href: "/app/catalog",
          },
          {
            title: "Merchant",
            icon: StoreOutlinedIcon,
            href: "/app/merchant",
          },
          {
            title: "ค้นหาบิล",
            href: "/app/bill",
            icon: SearchOutlinedIcon,
          },
          {
            title: "ค้นหาใบวางบิล",
            href: "/app/invoice",
            icon: SearchOutlinedIcon,
          },
          {
            title: "ใบคืนสินค้า",
            href: "/app/return-catalog",
            icon: AssignmentReturnOutlinedIcon,
          },
          {
            title: "ใบเบิกสินค้า",
            href: "/app/ream-catalog",
            icon: AddShoppingCartOutlinedIcon,
          },
          {
            title: "สรุปรายเดือน",
            href: "/app/dashboard",
            icon: BarChartOutlinedIcon,
          },
          {
            title: "สรุปประจำปี",
            href: "/app/year/dashboard",
            icon: BarChartOutlinedIcon,
          },
          {
            title: "สรุปทุกปี",
            href: "/app/all/dashboard",
            icon: TrendingUpIcon,
          },
          // {
          //   title: "Logging",
          //   href: "/app/activity",
          //   icon: HttpsOutlinedIcon,
          // },
        ],
      },
    ]);
  }, []);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/app">
              <Logo />
              {/* <img className={classes.image} alt="Logo" src={PCL_LogoNav} /> */}
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections.map((section, index) => (
            <List
              key={index}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: "location.pathname",
                tier: user?.tier,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
