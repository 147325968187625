import { apiCall } from "src/utils/axios";

export const apiGetMerchant = () => {
  return apiCall("GET", "api/v1/merchant", null, null);
};

export const apiGetMerchantByID = (id) => {
  return apiCall("GET", `api/v1/${id}/merchant`, null, null);
};

export const apiCreateMerchant = (payload) => {
  return apiCall("POST", "api/v1/create-merchant", payload, null);
};

export const apiEditMerchant = (payload) => {
  const url = `api/v1/update-merchant/${payload.id}`;
  return apiCall("POST", url, payload.data, null);
};

export const apiDeleteMerchant = (payload) => {
  const url = `api/v1/delete-merchant/${payload}`;
  return apiCall("POST", url, null, null);
};
