import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import routes, { renderRoutes } from "../src/routes";
import { createTheme } from "../src/theme";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { ModalSpinner, DialogProvider } from "src/components";

function App() {
  const theme = createTheme({
    theme: "LIGHT",
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <CssBaseline />
        <Router>
          <div className="App">{renderRoutes(routes)}</div>
        </Router>
        <ModalSpinner />
        <DialogProvider />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
