import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import { THEMES } from "../constants";
import { softShadows, strongShadows } from "./shadows";
import typography from "./typography";
import Roboto from "./font";
import Colors from "../components/Colors";

const baseOptions = {
  direction: "ltr",
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": [Roboto],
      },
    },
  },
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          "&::placeholder": {
            color: "gray",
          },
          "& .MuiFormLabel-root": {
            color: "red", // or black
          },
        },
      },
    },
    palette: {
      type: "light",
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: "#f4f6f8",
        paper: colors.common.white,
      },
      primary: {
        main: Colors.COLOR_MAIN,
      },
      secondary: {
        main: Colors.COLOR_MAIN,
      },
      text: {
        primary: Colors.TEXT_BLACK,
        secondary: Colors.DIVIDER_GRAY,
        white: Colors.TEXT_WHITE,
        green: Colors.COLOR_MAIN,
        gray: Colors.TEXT_GRAY,
        btnMain: Colors.TEXT_WHITE,
        btnMainHover: Colors.COLOR_MAIN,
        btnWhite: Colors.COLOR_SUB,
        btnWhiteHover: Colors.UNNAMED_COLOR_FFFFFF,
        dividerGray: Colors.DIVIDER_GRAY,
      },
      btn: {
        main: Colors.COLOR_MAIN,
        mainBorder: `solid 1px ${Colors.COLOR_MAIN}`,
        mainBorderHover: `solid 1px ${Colors.COLOR_MAIN}`,
        whiteBorder: `solid 1px ${Colors.TEXT_WHITE}`,
        whiteHover: Colors.COLOR_SUB,
        whiteBorderHover: `solid 1px ${Colors.COLOR_SUB}`,
        red: Colors.RED,
        green: Colors.GREEN,
        orange: Colors.ORANGE,
        gray: `solid 1px ${Colors.TEXT_GRAY}`,
        white: `solid 1px ${Colors.TEXT_WHITE}`,
      },
    },
    shadows: softShadows,
  },
];

/// font
export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
