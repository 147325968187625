import { apiCall } from "src/utils/axios";

export const apiSaveBill = (payload) => {
  return apiCall("POST", "api/v1/bill/save", payload, null);
};

export const apiRequestNewBillID = () => {
  return apiCall("GET", "api/v1/bill/request-new-bill-id", null, null);
};

export const apiGetBillsWithMerchants = (payload) => {
  return apiCall("GET", "api/v1/bill", null, payload);
};

export const apiGetBillByID = (payload) => {
  return apiCall("GET", "api/v1/bill", null, payload);
};

export const apiGetBillByMerchantID = (payload) => {
  return apiCall(
    "GET",
    `api/v1/bill/merchant-id/${payload.merchant_id}`,
    null,
    payload.query
  );
};

export const apiDeleteBillByID = (payload) => {
  return apiCall("DELETE", `api/v1/bill/delete/${payload}`, null, null);
};
